// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Input___3LNex {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n}\n.index__Input___3LNex .index__Title___39pz_ {\n  font-weight: bold;\n  font-size: 12px;\n  margin-bottom: 5px;\n}\n.index__Input___3LNex .index__InputField___2UdUt {\n  border: 1px solid rgba(224, 224, 224, 0.5);\n  align-self: stretch;\n}\n.index__Input___3LNex .index__InputField___2UdUt input {\n  font-size: 14px;\n  flex-grow: 2;\n  color: inherit;\n  width: 100%;\n  height: 100%;\n}\n.index__Input___3LNex .index__InputField___2UdUt input:focus {\n  outline: none;\n  border: none;\n}\n.index__Input___3LNex .index__InputField___2UdUt input::placeholder {\n  color: #707372;\n  opacity: 1;\n}\n.index__Input___3LNex .index__InputField___2UdUt input:-ms-input-placeholder {\n  color: #707372;\n}\n.index__Input___3LNex .index__InputField___2UdUt input::-ms-input-placeholder {\n  color: #707372;\n}\n.index__Input___3LNex.index__Focus___TMHQN .index__Title___39pz_ {\n  color: #A30B35;\n  transition: 40ms linear color;\n}\n.index__Input___3LNex.index__Focus___TMHQN .index__InputField___2UdUt {\n  border: 1px solid #A30B35;\n  transition: 400ms linear border, 400ms linear box-shadow;\n}", ""]);
// Exports
exports.locals = {
	"Input": "index__Input___3LNex",
	"Title": "index__Title___39pz_",
	"InputField": "index__InputField___2UdUt",
	"Focus": "index__Focus___TMHQN"
};
module.exports = exports;
