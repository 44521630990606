// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Card___3-xuV {\n  display: flex;\n  flex-direction: column;\n  padding: 31px 39px;\n  flex-grow: 1;\n  flex-basis: 0;\n  background: #ffffff;\n  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 5px rgba(0, 0, 0, 0.03), 0 6px 7px rgba(0, 0, 0, 0.02), 0 8px 9px rgba(0, 0, 0, 0.01);\n  transition: box-shadow ease-out 100ms;\n  margin-right: 20px;\n}\n.index__Card___3-xuV:hover {\n  transition: box-shadow ease-out 100ms;\n  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.02), 0 3px 3px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01), 0 5px 5px rgba(0, 0, 0, 0.01);\n}\n.index__Card___3-xuV > * {\n  margin-bottom: 16px;\n}\n.index__Card___3-xuV > *:last-child {\n  margin-bottom: 0;\n}\n.index__Card___3-xuV .index__InfoText___1R7I4 {\n  font-size: small;\n}\n.index__Card___3-xuV .index__BalanceText___Ellm9 {\n  font-size: xx-large;\n  font-weight: bold;\n  margin-right: 20px;\n}\n.index__Card___3-xuV .index__content___2g36E {\n  flex-direction: row;\n  display: flex;\n  align-items: center;\n}\n.index__Card___3-xuV .index__header___2GQjG {\n  display: flex;\n  flex-direction: row;\n}\n.index__Card___3-xuV .index__Icon___3EsbK {\n  margin-right: 5px;\n}\n.index__Card___3-xuV .index__CardTitle___s8Mjm {\n  font-size: 18px;\n}\n\n.index__Card___3-xuV:last-of-type {\n  margin-right: 0;\n}\n\n@media only screen and (max-width: 800px) {\n  .index__Card___3-xuV {\n    margin-right: 0px;\n    margin-top: 16px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Card": "index__Card___3-xuV",
	"InfoText": "index__InfoText___1R7I4",
	"BalanceText": "index__BalanceText___Ellm9",
	"content": "index__content___2g36E",
	"header": "index__header___2GQjG",
	"Icon": "index__Icon___3EsbK",
	"CardTitle": "index__CardTitle___s8Mjm"
};
module.exports = exports;
