// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__SearchInput___1qj2v {\n  display: flex;\n  align-items: stretch;\n  padding: 8px 16px;\n  background-color: white;\n}\n.index__SearchInput___1qj2v .index__Icon___2hhnM {\n  flex-shrink: 0;\n}\n.index__SearchInput___1qj2v .index__Input___oy0jX {\n  font-weight: bold;\n  font-size: 14px;\n  color: #54565a;\n  flex-grow: 2;\n  padding: 0 8px;\n}", ""]);
// Exports
exports.locals = {
	"SearchInput": "index__SearchInput___1qj2v",
	"Icon": "index__Icon___2hhnM",
	"Input": "index__Input___oy0jX"
};
module.exports = exports;
