//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Badge, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import ExternalVideo from '@classes/externalVideo';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Media extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      externalVideo: null,
      imageUrl: null,
    };
  }

  componentDidMount() {
    const {
      location: { state },
    } = this.props;

    if (state.videoUrl) {
      this.setState({
        externalVideo: new ExternalVideo(state.videoUrl),
      });
    } else if (state.imageUrl) {
      this.setState({
        imageUrl: state.imageUrl,
      });
    }
  }

  videoComponent() {
    const { externalVideo } = this.state;

    return (
      <div
        className={styles.Content}
        dangerouslySetInnerHTML={{ __html: externalVideo.embedCode() }}
      ></div>
    );
  }

  imageComponent() {
    const { imageUrl } = this.state;

    return (
      <div className={styles.Content}>
        <img src={imageUrl} className={styles.Image} />
      </div>
    );
  }

  render() {
    const { externalVideo, imageUrl } = this.state;
    const {
      history,
      location: { state },
    } = this.props;

    const componentClasses = classNames(styles.Media, {
      [styles.MediaVideo]: externalVideo,
    });

    return (
      <Modal.Base
        className={componentClasses}
        onClose={() => history.push(state.from)}
      >
        {externalVideo && this.videoComponent()}
        {imageUrl && this.imageComponent()}
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Media;
