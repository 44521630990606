//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { tilesUrls } from '@cmp/images';
import { Trans, withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ExclusiveAccess extends React.Component {
  productBannerComponent(imageUrl, className) {
    const componentClasses = classNames(styles.ProductBanner, {
      [className]: className,
    });

    return (
      <div
        className={componentClasses}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
      </div>
    );
  }

  render() {
    const { t, i18n } = this.props;
    const componentClasses = classNames();
    const Tiles = tilesUrls(i18n);

    return (
      <div className={styles.ExclusiveAccess}>
        <div className={styles.Subcontent}>
          <span className={styles.Title}>
            {t('landing_GetAccessToSpecialty')}
          </span>
          <span className={styles.Subtitle}>
            {t('landing_GetAccessToSpecialtySubtitle')}
          </span>
          <div className={styles.Products}>
            {this.productBannerComponent(
              Tiles[0],
              classNames(styles.Tall)
            )}
            {this.productBannerComponent(
              Tiles[1]
            )}
            {this.productBannerComponent(
              Tiles[2]
            )}
          </div>
        </div>
        <span className={styles.Disclaimer}>
          <Trans i18nKey={'landing_ExclusiveAccessDisclaimer'}>
            You can receive this money in your bank account. If you prefer not
            to accept the money, Elta ProCart will direct the{' '}
            <a
              className={styles.Link}
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/watch?v=fxlqHJjjYAg"
            >
              funds to BSBF®
            </a>
          </Trans>
        </span>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(ExclusiveAccess);
