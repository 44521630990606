//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, HowItWorks, Footer } from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import {
  AboutBackground,
  PatientBenefit,
  PatientBenefitResponsive,
} from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers ----------------------------------------------------------------------
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class About extends React.Component {
  textContainer(
    title,
    subtitleComponent,
    subtitle,
    footerComponent,
    className
  ) {
    const componentClasses = classNames(styles.TextContainer, {
      [className]: className,
    });

    return (
      <div className={componentClasses} key={title}>
        <h3 className={styles.Title}>{title}</h3>
        {subtitleComponent}
        {subtitle && <span className={styles.Subtitle}>{subtitle}</span>}
        {footerComponent}
      </div>
    );
  }

  sectionsComponent(sections) {
    let components = [];
    sections.forEach((x) =>
      components.push(
        this.textContainer(
          x.title,
          x.subtitleComponent,
          x.subtitle,
          null,
          styles.Section
        )
      )
    );

    return <>{components}</>;
  }

  render() {
    const componentClasses = classNames(styles.About);
    const { t } = this.props;

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('about_About')}</title>
        </Helmet>

        <Header />
        <AboutBackground className={styles.Banner} />
        <div className={styles.Container}>
          <h3 className={styles.PageTitle}>{t('about_AboutUs')}</h3>
          {/* Intro */}
          {this.textContainer(
            t('about_IntroText'),
            null,
            t('about_IntroSubtitle')
          )}

          {/* What is CPD? */}
          {this.textContainer(
            t('about_WhatIsText'),
            null,
            t('about_WhatIsSubtitle')
          )}

          {/* How It Works */}
          {this.textContainer(
            t('about_HowItWorks'),
            <HowItWorks withDescription={true} className={styles.HowItWorks} />,
            t('about_HowItWorksSubtitle'),
            null,
            styles.HowItWorks
          )}

          {/* Patient Benefit */}
          {this.textContainer(
            t('about_PatientBenefitText'),
            null,
            t('about_PatientBenefitSubtitle'),
            <>
              <PatientBenefit className={styles.Image} />
              <PatientBenefitResponsive
                className={classNames(styles.Image, styles.ImageResponsive)}
              />
            </>,
            styles.PatientBenefit
          )}

          {/* Practice Benefit */}
          {this.textContainer(
            t('about_PracticeBenefitText'),
            this.sectionsComponent([
              {
                title: t('about_PracticeBenefitClearsOfficeSpace'),
                subtitle: t('about_PracticeBenefitClearsOfficeSpaceSubtitle'),
              },
              {
                title: t('about_PracticeBenefitImproveCompliance'),
                subtitle: t('about_PracticeBenefitImproveComplianceSubtitle'),
              },
              {
                title: t('about_PracticeBenefitAddedBonusText'),
                subtitleComponent: (
                  <p className={styles.Subtitle}>
                    {t('about_PracticeBenefitAddedBonus1')}
                    <ol className={styles.SubtitleList}>
                      <li className={styles.SubtitleListItem}>
                        {t('about_PracticeBenefitAddedBonus2')}
                      </li>
                      <li className={styles.SubtitleListItem}>
                        {t('about_PracticeBenefitAddedBonus3')}{' '}
                        <a
                          className={styles.SubtitleLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.youtube.com/watch?v=fxlqHJjjYAg"
                        >
                          BSBF®
                        </a>
                      </li>
                      <li className={styles.SubtitleListItem}>
                        {t('about_PracticeBenefitAddedBonus4')}{' '}
                        <a
                          className={styles.SubtitleLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.youtube.com/watch?v=fxlqHJjjYAg"
                        >
                          BSBF®
                        </a>
                      </li>
                    </ol>
                  </p>
                ),
              },
            ]),
            null,
            null,
            styles.PatientBenefitSections
          )}

          {/* Final message */}
          <h2 className={styles.FooterMessage}>{t('about_FinalMessage')}</h2>
          <span className={styles.Disclaimer}>
            {t('about_FinalMessageDescription')}
          </span>
        </div>
        <Footer className={styles.Footer} />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(About);
