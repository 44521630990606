//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import FocusTrap from 'focus-trap-react';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ModalBase extends React.Component {
  componentDidUpdate(prevProps) {
    const { visible, modalDidOpen, modalDidClose } = this.props;

    if (visible && prevProps.visible !== visible && modalDidOpen) {
      modalDidOpen();
    } else if (!visible && prevProps.visible !== visible && modalDidClose) {
      modalDidClose();
    }
  }

  render() {
    const { className, onClose, children } = this.props;

    const componentClasses = classNames(styles.ModalBase);

    return (
      <FocusTrap
        focusTrapOptions={{
          escapeDeactivates: false,
        }}
        >
        <div
          className={componentClasses}
          onKeyDown={(e) => {
            if (e.key === 'Escape') onClose && onClose();
          }}>
        >
          <div className={styles.BackgroundOverlay} onClick={onClose}></div>
          <div className={classNames(styles.Content, { [className]: className })}>
            {onClose && (
              <div
                className={styles.CloseIcon}
                tabIndex={0}
                onClick={onClose}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') onClose();
                }}>
                Close
              </div>
            )}
            {children}
          </div>
        </div>
      </FocusTrap>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ModalBase;
