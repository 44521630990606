// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__CheckoutDetails___1i0Ps {\n  display: flex;\n  flex-direction: column;\n  margin-top: 25px;\n}\n.index__CheckoutDetails___1i0Ps > * {\n  margin-bottom: 23px;\n}\n.index__CheckoutDetails___1i0Ps > *:last-child {\n  margin-bottom: 0;\n}\n.index__CheckoutDetails___1i0Ps .index__Button___RXbhO {\n  color: #FFFFFF;\n}\n.index__CheckoutDetails___1i0Ps .index__Values___2Lydi {\n  display: flex;\n  flex-direction: column;\n}\n.index__CheckoutDetails___1i0Ps .index__Values___2Lydi > * {\n  margin-bottom: 11px;\n}\n.index__CheckoutDetails___1i0Ps .index__Values___2Lydi > *:last-child {\n  margin-bottom: 0;\n}\n.index__CheckoutDetails___1i0Ps .index__Values___2Lydi .index__Row___1MwAp {\n  display: flex;\n  justify-content: space-between;\n}\n.index__CheckoutDetails___1i0Ps .index__Values___2Lydi .index__Row___1MwAp .index__Title___1JMIC {\n  font-size: 14px;\n  color: #54565a;\n}\n.index__CheckoutDetails___1i0Ps .index__Values___2Lydi .index__Row___1MwAp .index__Value___3K9sj {\n  font-weight: bold;\n  font-size: 16px;\n}", ""]);
// Exports
exports.locals = {
	"CheckoutDetails": "index__CheckoutDetails___1i0Ps",
	"Button": "index__Button___RXbhO",
	"Values": "index__Values___2Lydi",
	"Row": "index__Row___1MwAp",
	"Title": "index__Title___1JMIC",
	"Value": "index__Value___3K9sj"
};
module.exports = exports;
