//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Button, ButtonStyle } from '@cmp/common';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { ProductsList } from './components';
import {
  FilterSelector,
  SearchInput,
  Paginate,
  ViewRecommendationsButton,
} from '@cmp/common';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Products as ProductsRequest } from '@api/endpoints/get/products';
import { Providers as ProvidersRequest } from '@api/endpoints/get/providers';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { searchIconUrl } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { shopifyFormatter, responseFormatter } from '@helpers/formatter';
import { ProductsSortingOptions, EltaProductCategories } from '@helpers/constants/products';
import { getValue, storeValue } from '@helpers/localStorage';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { UserStorageKey, refCode } from '@helpers/constants/localStorage';
import { findPayoutOption } from '@helpers/constants/user';
import { withTranslation } from 'react-i18next';
import { StoreConfigContext } from '@helpers/storeConfigContext';
import { productsFilterByShopifyCategory } from '@helpers/constants/products';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue:
        new URLSearchParams(window.location.search).get('search') || '',
      sortBy: ProductsSortingOptions.find((x) => x.default),
      selectedTagId: null,
      paginatedUrl: null,
    };
  }

  headerComponent() {
    const { t } = this.props;
    return (
      <header className={styles.Header}>
        <h1 className={styles.Title}>{t('dashboard_SolutionsForPatients')}</h1>
        <span className={classNames(styles.Subtitle, styles.ProductsSubtitle)}>
          {t('dashboard_SolutionsForPatientsSubtitle')}
        </span>
      </header>
    );
  }

  tagsSection() {
    return (
      <div className={styles.Tags}>
        {EltaProductCategories.map((item, idx) => {
          const isSelected = idx === this.state.selectedTagId
          const componentClasses = classNames(styles.Tag, {
                  [styles.Selected]: isSelected,
          });
          const newSelection = isSelected ? null : idx;

          return (
            <span
              className={componentClasses}
              key = {idx}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter')
                  this.setState({
                    selectedTagId: newSelection
                  })
              }}
              onClick = {()=>{
                this.setState({
                  selectedTagId: newSelection
                })
              }}>
              {item.title}
            </span>
          )})}
      </div>
    )
  }

  listHeaderComponent() {
    const { t, i18n } = this.props;
    const { searchValue } = this.state;

    return (
      <div className={styles.ListHeader}>
        {this.tagsSection()}
        <div className={styles.Filter}>
          <SearchInput
            t={t}
            value={searchValue}
            placeholder={t('dashboard_SearchFor')}
            icon={searchIconUrl(i18n)}
            onChange={(searchValue) => {
              this.setState({ searchValue, paginatedUrl: null }, () => {
                history.pushState(
                  searchValue,
                  t('dashboard_Products'),
                  `?search=${searchValue}`
                );
                if (this.searchTimeout) clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(
                  this.refetch,
                  searchValue.length > 0 ? 600 : 0
                );
              });
            }}
          />
        </div>
      </div>
    );
  }

  listComponent() {
    const {
      sortBy,
      searchValue,
      selectedTagId,
      paginatedUrl,
    } = this.state;

    return (
      <div className={styles.List}>
        {this.listHeaderComponent()}
        <ProductsRequest
          params={
            !paginatedUrl && {
              title: searchValue,
              sort: sortBy.value,
              limit: 50,
            }
          }
          customUrl={paginatedUrl}
        >
          {({ data, headers, refetch }) => {
            this.refetch = refetch;

            let { products } = data || {};
            const paginationUrls = responseFormatter
              .headers(headers)
              .pagination();

            if (products) {
              products =
                productsFilterByShopifyCategory(products, selectedTagId)
              products = products.sort(
                (a, b) => a.title.localeCompare(b.title)
              );
            }

            return (
              <>
                <ProductsList
                  products={products}
                  className={styles.ProductsList}
                  onAdd={() => undefined}
                />
                <Paginate
                  className={styles.Paginate}
                  onNext={
                    paginationUrls.nextUrl &&
                    (() =>
                      this.setState(
                        { paginatedUrl: paginationUrls.nextUrl },
                        refetch
                      ))
                  }
                  onPrevious={
                    paginationUrls.previousUrl &&
                    (() =>
                      this.setState(
                        { paginatedUrl: paginationUrls.previousUrl },
                        refetch
                      ))
                  }
                />
              </>
            );
          }}
        </ProductsRequest>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.Products);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('dashboard_Products')}</title>
        </Helmet>

        {this.headerComponent()}
        {this.listComponent()}
        <ViewRecommendationsButton className={styles.Recommendations} />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Products);

Products.contextType = StoreConfigContext;
