//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Popover, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class GenericPopover extends React.Component {
  render() {
    const {
      history,
      location,
      title,
      subtitle,
      auxiliar,
      visible,
      action,
      to,
      onClick,
      onClose,
      className,
    } = this.props;
    const componentClasses = classNames(styles.GenericPopover, className);

    return visible ? (
      <Popover.Base
        className={componentClasses}
        onClose={onClose || (() => history.push(location.state.from))}
      >
        {title && <h2 className={styles.Title}>{title}</h2>}
        {subtitle && <span className={styles.Subtitle}>{subtitle}</span>}
        {auxiliar && <span className={styles.Auxiliar}>{auxiliar}</span>}
      </Popover.Base>
    ) : null;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(GenericPopover);
