// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__InfluencerVideo___32n6F {\n  padding: 50px 0 92px 0;\n  text-align: center;\n}\n.index__InfluencerVideo___32n6F .index__Subcontent___ZxCVQ > .index__Overhead___2ujPA {\n  color: #A30B35;\n  font-size: 16px;\n}\n.index__InfluencerVideo___32n6F .index__Subcontent___ZxCVQ > .index__Title___3_Fgp {\n  color: #000000;\n  font-size: 40px;\n  font-weight: bold;\n}\n.index__InfluencerVideo___32n6F .index__Subcontent___ZxCVQ > .index__Subtitle___227RT {\n  color: #54565a;\n  font-weight: normal;\n  font-size: 16px;\n}\n.index__InfluencerVideo___32n6F .index__Subcontent___ZxCVQ .index__Button___2Glgk {\n  margin-top: 23px;\n}\n@media only screen and (max-width: 955px) {\n  .index__InfluencerVideo___32n6F .index__Subcontent___ZxCVQ > .index__Title___3_Fgp {\n    font-size: 32px;\n  }\n}\n@media only screen and (max-width: 320px) {\n  .index__InfluencerVideo___32n6F .index__Subcontent___ZxCVQ > .index__Title___3_Fgp {\n    font-size: 24px;\n  }\n}\n.index__InfluencerVideo___32n6F .index__Box___1csF- {\n  max-width: 900px;\n  max-height: auto;\n  margin: auto;\n}\n.index__InfluencerVideo___32n6F .index__Container___1_o59 {\n  position: relative;\n  overflow: hidden;\n  width: 100%;\n  padding-top: 56.25%;\n}\n.index__InfluencerVideo___32n6F .index__ResponsiveIframe___2H8jy {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n}\n@media only screen and (max-width: 955px) {\n  .index__InfluencerVideo___32n6F {\n    padding: 26px 40px;\n  }\n  .index__InfluencerVideo___32n6F .index__Subcontent___ZxCVQ .index__Title___3_Fgp,\n.index__InfluencerVideo___32n6F .index__Subcontent___ZxCVQ .index__Subtitle___227RT {\n    text-align: center;\n  }\n}", ""]);
// Exports
exports.locals = {
	"InfluencerVideo": "index__InfluencerVideo___32n6F",
	"Subcontent": "index__Subcontent___ZxCVQ",
	"Overhead": "index__Overhead___2ujPA",
	"Title": "index__Title___3_Fgp",
	"Subtitle": "index__Subtitle___227RT",
	"Button": "index__Button___2Glgk",
	"Box": "index__Box___1csF-",
	"Container": "index__Container___1_o59",
	"ResponsiveIframe": "index__ResponsiveIframe___2H8jy"
};
module.exports = exports;
