// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__BasicInfo___2cw28 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n.index__BasicInfo___2cw28 .index__Title___3-IXT {\n  font-weight: bold;\n  font-size: 24px;\n  margin-bottom: 8px;\n}\n.index__BasicInfo___2cw28 .index__Badge___1iEvC {\n  margin-top: 18px;\n}", ""]);
// Exports
exports.locals = {
	"BasicInfo": "index__BasicInfo___2cw28",
	"Title": "index__Title___3-IXT",
	"Badge": "index__Badge___1iEvC"
};
module.exports = exports;
