// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__ResetPassword___1ey46 {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  background-color: #fafafb;\n  height: 100%;\n}\n.index__ResetPassword___1ey46 .index__Form___2QQdl {\n  margin: 100px 0 20px 0;\n}\n@media only screen and (max-width: 955px) {\n  .index__ResetPassword___1ey46 .index__Form___2QQdl {\n    width: 90%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"ResetPassword": "index__ResetPassword___1ey46",
	"Form": "index__Form___2QQdl"
};
module.exports = exports;
