// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Badge___2sMOK {\n  padding: 3px 12px;\n  font-weight: bold;\n  font-size: 14px;\n}\n.index__Badge___2sMOK.index__Purchased___muVN3, .index__Badge___2sMOK.index__AdditionalPurchase___2R_-2, .index__Badge___2sMOK.index__SubscriptionPurchase___nGCK_ {\n  color: darkgreen;\n  background-color: #f3f5f6;\n}\n.index__Badge___2sMOK.index__PartiallyPurchased___G-X91 {\n  color: #A30B35;\n  background-color: #f3f5f6;\n}\n.index__Badge___2sMOK.index__Pending___3oFCA, .index__Badge___2sMOK.index__PendingAdditionalPurchase___2tj7x {\n  color: #54565a;\n  background-color: #f3f5f6;\n}", ""]);
// Exports
exports.locals = {
	"Badge": "index__Badge___2sMOK",
	"Purchased": "index__Purchased___muVN3",
	"AdditionalPurchase": "index__AdditionalPurchase___2R_-2",
	"SubscriptionPurchase": "index__SubscriptionPurchase___nGCK_",
	"PartiallyPurchased": "index__PartiallyPurchased___G-X91",
	"Pending": "index__Pending___3oFCA",
	"PendingAdditionalPurchase": "index__PendingAdditionalPurchase___2tj7x"
};
module.exports = exports;
