//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { logoPlaceholderUrl, PlayIcon } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { isTouchEnabled } from '@helpers/device';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Slideshow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: null,
      currentVideo: null,
    };
  }

  componentDidMount() {
    this.setupCurrentImageIfNeeded();
  }

  componentDidUpdate() {
    this.setupCurrentImageIfNeeded();
  }

  setupCurrentImageIfNeeded() {
    const { currentImage, currentVideo } = this.state;
    const { images } = this.props;

    if (!currentImage && !currentVideo && images.length > 0) {
      this.setState({ currentImage: images[0] });
    }
  }

  allImagesComponent() {
    const { videos, images, i18n, alt } = this.props;
    if (!images) return '';

    return (
      <div className={styles.AllImages}>
        {images.map((image) => (
          <img
            className={styles.Thumbnail}
            src={image.src}
            key={image.id}
            alt={alt}
            onMouseOver={() => {
              if (!isTouchEnabled()) {
                this.setState({ currentImage: image, currentVideo: null });
              }
            }}
            onClick={() => {
              if (isTouchEnabled()) {
                this.setState({ currentImage: image, currentVideo: null });
              }
            }}
          />
        ))}
        {videos &&
          videos.map((video) => {
            if (!video.isValid()) return '';

            return (
              <div
                className={classNames(styles.Thumbnail, styles.VideoThumnail)}
                key={video.id()}
                onMouseOver={() => {
                  if (!isTouchEnabled()) {
                    this.setState({ currentImage: null, currentVideo: video });
                  }
                }}
                onClick={() => {
                  if (isTouchEnabled()) {
                    this.setState({ currentImage: null, currentVideo: video });
                  } else {
                    this.pushMedia(null, video.videoUrl);
                  }
                }}
              >
                <div className={styles.Overlay}>
                  <PlayIcon className={styles.PlayIcon} />
                </div>
                <img src={video.thumbnailUrl() || logoPlaceholderUrl(i18n)} />
              </div>
            );
          })}
      </div>
    );
  }

  videoPreviewComponent() {
    const { currentVideo } = this.state;
    const { history, i18n } = this.props;
    const videoPreviewClasses = classNames(styles.Main, styles.VideoPreview);

    return (
      <div className={videoPreviewClasses}>
        <img
          src={currentVideo.thumbnailUrl() || logoPlaceholderUrl(i18n)}
          className={styles.Current}
          onClick={() => this.pushMedia(null, currentVideo.videoUrl)}
        />
        <PlayIcon className={styles.PlayIcon} />
      </div>
    );
  }

  mainImageComponent() {
    const { currentImage } = this.state;
    const { i18n, alt } = this.props;

    return (
      <div className={styles.Main}>
        <img
          src={(currentImage && currentImage.src) || logoPlaceholderUrl(i18n)}
          alt={alt}
          className={styles.Current}
          onClick={() => this.pushMedia(currentImage && currentImage.src)}
        />
      </div>
    );
  }

  pushMedia(imageUrl, videoUrl) {
    const { history } = this.props;

    history.push({
      pathname: '/media',
      state: {
        modal: true,
        from: history.location,
        imageUrl,
        videoUrl,
      },
    });
  }

  render() {
    const { currentImage, currentVideo } = this.state;
    const { className } = this.props;
    const componentClasses = classNames(styles.Slideshow, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {currentImage && this.mainImageComponent()}
        {currentVideo && this.videoPreviewComponent()}
        {this.allImagesComponent()}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(withTranslation()(Slideshow));
