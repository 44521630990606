//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import qs from 'query-string';
import { FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Form } from '@cmp/common';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { finishConnect } from '@api/endpoints/post/payments';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { auth } from '@helpers/auth';
import { gigyaFormatter } from '@helpers/formatter';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    let that = this;
    const { location } = this.props;
    const params = qs.parse(location.search);

    console.log('1. componentDidMount');

    if (params.error) {
      this.setState({ error: true, errorMessage: params.error_description });
    } else if (params.state && params.code) {
      this.verifyConnect(params);
    } else {
      window.location = '/';
    }
  }

  verifyConnect({ state, code }) {
    let that = this;

    console.log('2. verifyConnect');

    finishConnect(state, code)
      .then((res) => {
        console.log('3. finishConnect');

        window.location = '/';
      })
      .catch((error) => that.setState({ error }));
  }

  successComponent() {
    const { t } = this.props;
    return (
      <Form
        className={styles.Form}
        title={t('payment_Success')}
        subtitle={t('payment_SuccessSubtitle')}
      >
        <FaSpinner className={styles.Spinner} />
      </Form>
    );
  }

  errorComponent() {
    const { errorMessage } = this.state;

    return (
      <Form
        className={styles.Form}
        title={t('payment_Error')}
        subtitle={errorMessage || t('payment_GoBackTryAgain')}
        additionalComponent={
          <Link to="/" className={styles.GoBack}>
            {t('payment_GoBack')}
          </Link>
        }
      />
    );
  }

  render() {
    const componentClasses = classNames(styles.Payment);
    const { error } = this.state;

    return (
      <div className={componentClasses}>
        <Header />
        <div className={styles.Content}>
          {error ? this.errorComponent() : this.successComponent()}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Payment);
