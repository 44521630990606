//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withAuthUser } from '@helpers/auth';
import { withReferral } from '@helpers/referrals';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { hasGigyaLoggedIn } from '../../../helpers/account';
import { trackViewCart } from '@helpers/tracking';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ViewRecommendationsButton extends React.Component {
  render() {
    const { __authUser, __referral, location, className, t } = this.props;
    if (
      !__authUser ||
      !hasGigyaLoggedIn(__authUser) ||
      !__referral ||
      !__referral.items ||
      __referral.items.length <= 0
    )
      return '';

    const componentClasses = classNames(styles.ViewRecommendationsButton, {
      [className]: className,
    });

    return (
      <Link
        to={{
          pathname: '/recommendation/review',
          state: { from: location, modal: true },
        }}
        className={componentClasses}
        onClick={() => trackViewCart(__referral)}
      >
        {t('dashboard_ViewRecommendation')} ({__referral.items.length})
      </Link>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(
  withAuthUser(withReferral(withTranslation()(ViewRecommendationsButton)))
);
