//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { awardIconUrl, sendIconUrl, shoppingBagIconUrl } from '@cmp/images';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class HowItWorks extends React.Component {
  constructor(props) {
    super(props);

    this.stepComponent = this.stepComponent.bind(this);
    this.steps = [
      {
        assetUrlFn: shoppingBagIconUrl,
        titleI18nKey: 'landing_FindProducts',
        subtitleI18nKey: 'landing_FindProductsSubtitle',
        descriptionI18nKey: 'landing_FindProductsDescription',
        altI18nKey: 'alt_find_products',
      },
      {
        assetUrlFn: sendIconUrl,
        titleI18nKey: 'landing_SendRecommendations',
        subtitleI18nKey: 'landing_SendRecommendationsSubtitle',
        descriptionI18nKey: 'landing_SendRecommendationsDescription',
        altI18nKey: 'alt_send_recommendations',
      },
      {
        assetUrlFn: awardIconUrl,
        titleI18nKey: 'landing_SeeSuccess',
        subtitleI18nKey: 'landing_SeeSuccessSubtitle',
        descriptionI18nKey: 'landing_SeeSuccessDescription',
        altI18nKey: 'alt_success_medal',
      },
    ];
  }

  stepComponent(item, index) {
    const { withDescription, t, i18n } = this.props;
    const componentClasses = classNames(styles.Step, {
      [item.className]: item.className,
    });

    return (
      <div className={componentClasses} key={item.titleI18nKey}>
        <span className={styles.Overhead}>
          {t('landing_Step')} {index + 1}
        </span>
        <div className={styles.Illustration}>
          <img alt={t(item.altI18nKey)} src={item.assetUrlFn(i18n)} className={styles.Image} />
        </div>
        {!withDescription && (
          <span className={styles.Title}>{t(item.titleI18nKey)}</span>
        )}
        {!withDescription && (
          <span className={styles.Subtitle}>{t(item.subtitleI18nKey)}</span>
        )}
        {withDescription && (
          <span className={styles.Description}>{item.descriptionI18nKey}</span>
        )}
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const componentClasses = classNames(styles.Steps, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {this.steps.map(this.stepComponent)}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(HowItWorks);
