//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import qs from 'query-string';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Badge, Button, ButtonStyle } from '@cmp/common';
import { BasicInfo, Products, CheckoutDetails } from '../../common';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Details as DetailsRequest } from '@api/endpoints/get/recommendation';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { referralFormatter } from '@helpers/formatter';
import { ReferralStatus } from '@helpers/constants/referral';
import { withTranslation } from 'react-i18next';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
    };
  }

  componentDidMount() {
    const { history, match: { params: { id } = {} } = {} } = this.props;

    if (!id) return history.replace('/recommendations');
    this.setState({ id });
  }

  droppedProductsComponent(purchaseProducts, droppedProducts, isPending) {
    const { t } = this.props;
    if (!droppedProducts || droppedProducts.length === 0) return null;

    const componentClasses = classNames(styles.DroppedProducts, {
      [styles.noMargin]: !purchaseProducts || purchaseProducts.length <= 0,
    });

    return (
      <div className={componentClasses}>
        {!isPending &&
          <span className={styles.Title}>
            {t('dashboard_ItemsWerentPurchased')}
          </span>}
        <Products products={droppedProducts} disabled={true} />
      </div>
    );
  }

  extraProductsComponent(purchaseProducts, extraProducts, isPending) {
    const { t } = this.props;
    if (!extraProducts || extraProducts.length === 0) return null;

    const componentClasses = classNames(styles.ExtraProducts, {
      [styles.noMargin]: !purchaseProducts || purchaseProducts.length <= 0,
    });

    return (
      <div className={componentClasses}>
        {!isPending &&
         <span className={styles.Title}>
          {t('dashboard_ItemsWereExtraPurchased')}
        </span>}
        <Products products={extraProducts} disabled={true} />
      </div>
    );
  }

  render() {
    const { id } = this.state;
    const { location, history, t } = this.props;
    const componentClasses = classNames(styles.Details);

    return (
      <Modal.Base
        className={componentClasses}
        onClose={() => history.push(location.state.from)}
      >
        <DetailsRequest pathParams={{ id }} skip={!id}>
          {({ loading, error, data }) => {
            if (!data) return '';

            const recommendationTitle = data.consumerName
              ? t('dashboard_RecommendationFor', { name: data.consumerName })
              : t('dashboard_Recommendation');
            const additionalPurchaseTitle = data.consumerName
              ? t('dashboard_PurchasedBy', { name: data.consumerName })
              : t('dashboard_Purchase');
            const purchaseProducts = referralFormatter.purchaseProducts(data);
            const droppedProducts = referralFormatter.droppedProducts(data);
            const extraProducts = referralFormatter.extraProducts(data);

            return (
              <>
                <Helmet>
                  <title>
                    {referralFormatter.status(data) ===
                    ReferralStatus.AdditionalPurchase
                      ? additionalPurchaseTitle
                      : recommendationTitle}
                  </title>
                </Helmet>

                <BasicInfo
                  title={
                    referralFormatter.status(data) ===
                    ReferralStatus.AdditionalPurchase
                      ? additionalPurchaseTitle
                      : recommendationTitle
                  }
                  subtitleComponent={
                    <span className={styles.DetailSubtitle}>
                      {referralFormatter.status(data) ===
                      ReferralStatus.AdditionalPurchase
                        ? t('dashboard_Purchased')
                        : t('dashboard_Recommended')}{' '}
                      {t('dashboard_On')}{' '}
                      {referralFormatter.formattedCreatedAt(data)}
                      <br />
                      {data.consumerEmail}
                      {data.consumerEmail && data.consumerPhone && ` · `}
                      {data.consumerPhone}
                    </span>
                  }
                  status={referralFormatter.status(data)}
                  className={styles.BasicInfo}
                />
                <Products products={purchaseProducts} />
                {this.droppedProductsComponent(
                  purchaseProducts,
                  droppedProducts,
                  referralFormatter.status(data) === ReferralStatus.Pending
                )}
                {this.extraProductsComponent(
                  purchaseProducts,
                  extraProducts,
                  referralFormatter.status(data) === ReferralStatus.Pending
                )}
                <CheckoutDetails
                  referral={data}
                  action={t('dashboard_RecommendAgain')}
                  onClick={() =>
                    history.push({
                      pathname: '/recommendation/patient',
                      state: {
                        fromReferral: data,
                        from: location.state.from,
                        modal: true,
                      },
                    })
                  }
                />
              </>
            );
          }}
        </DetailsRequest>
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Details);
