//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Form, Button, ButtonStyle } from '@cmp/common';
import { Dashboard } from '@cmp/client';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { signIn } from '@api/endpoints/post/auth';
import { startConnect } from '@api/endpoints/post/payments';
import { sendClinicCode } from '@api/endpoints/post/providers';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { verifyLogin } from '@helpers/gigya';
import { getValue, storeValue } from '@helpers/localStorage';
import { auth, deauth, withAuthUser } from '@helpers/auth';
import { getAspCode, hasGigyaLoggedIn } from '@helpers/account';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import {
  AuthorizationStorageKey,
  refCode,
} from '@helpers/constants/localStorage';
import { findPayoutOption } from '@helpers/constants/user';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { checkIfUserHasClinicCodeAssociated } from '../../../../../api/endpoints/get/providers';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user: null,
    };
    this.verifyAccount = this.verifyAccount.bind(this);
  }

  componentDidMount() {
    let that = this;
    const { __authUser } = this.props;
    verifyLogin(__authUser).then(that.verifyAccount).catch(deauth);
  }

  verifyAccount(user) {
    const { history } = this.props;
    const pushState = { fromCallback: true };

    console.log('1. verifyAccount');

    if (!hasGigyaLoggedIn(user)) {
      console.log('1.1 !hasGigyaLoggedIn');

      history.push({
        pathname: '/registration',
        state: pushState,
      });
    } else {
      this.verifyAuthenticationToken(user);
    }
  }

  verifyAuthenticationToken(user) {
    const authorizationToken = getValue(AuthorizationStorageKey);

    console.log('2. verifyAuthenticationToken');

    if (authorizationToken) {
      console.log('2.1 authorizationToken');
    } else {
      console.log('2.2 !authorizationToken');
      const aspCode = getAspCode();
      if (!aspCode) {
        deauth(); // "Gigya User Data has no aspCode"
        return;
      }
      this.authPRP(user, aspCode);
    }
  }

  authPRP(user, aspCode) {
    console.log('authPRP');
    signIn(user)
      .then(({ headers }) => {
        auth({}, true, headers);
        console.log('BearerToken Set');
        checkIfUserHasClinicCodeAssociated()
          .then((res) => {
            console.log('clinicCode Associated');
            window.document.location = '/'; // Login Succeeded
          })
          .catch(() => {
            console.log('clinicCode not Associated, we need to send it');
            sendClinicCode(aspCode)
              .then((res) => {
                console.log('clinicCode Sent');
                window.document.location = '/'; // Login Succeeded
              })
              .catch((err) => {
                // HTTP 400 is expected if AspCode is already set
                if (err.response.status !== 400) {
                  console.error('Unexpected sendingClinicCode response');
                  deauth(); // Login Failed
                }
              });
          });
      })
      .catch(deauth); // Login Failed
  }

  loadingComponent() {
    return (
      <Form className={styles.Form}>
        <FaSpinner className={styles.Spinner} />
      </Form>
    );
  }

  errorComponent() {
    const { t } = this.props;
    return (
      <Form
        className={styles.Form}
        title={t('GenericErrorMessage')}
        subtitle={t('login_PleaseReloadPage')}
        additionalComponent={
          <Link to="/login" className={styles.GoBack}>
            {t('login_GoBackToLogin')}
          </Link>
        }
      />
    );
  }

  idealComponent() {
    const { error } = this.state;

    if (error) {
      return this.errorComponent();
    } else {
      return this.loadingComponent();
    }
  }

  render() {
    const componentClasses = classNames(styles.Auth);

    return (
      <div className={componentClasses}>
        <Header />
        <div className={styles.Content}>{this.idealComponent()}</div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(Auth));
