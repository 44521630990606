// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".App__App___NYekn {\n  height: 100%;\n}\n.App__App___NYekn .App__Title___ziLk5 {\n  position: fixed;\n  bottom: 10px;\n  right: 10px;\n  font-size: 12px;\n  color: white;\n  background-color: rgba(44, 44, 44, 0.4);\n  padding: 2px 5px;\n  z-index: 998;\n}", ""]);
// Exports
exports.locals = {
	"App": "App__App___NYekn",
	"Title": "App__Title___ziLk5"
};
module.exports = exports;
