// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Patient___2Lm_n {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 60px;\n  max-width: 475px;\n}\n.index__Patient___2Lm_n > * {\n  margin-bottom: 32px;\n}\n.index__Patient___2Lm_n > *:last-child {\n  margin-bottom: 0;\n}\n.index__Patient___2Lm_n .index__Header___14zpe {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n.index__Patient___2Lm_n .index__Header___14zpe > * {\n  margin-bottom: 11px;\n}\n.index__Patient___2Lm_n .index__Header___14zpe > *:last-child {\n  margin-bottom: 0;\n}\n.index__Patient___2Lm_n .index__Header___14zpe .index__Title___2R93F {\n  font-weight: bold;\n  font-size: 24px;\n}\n.index__Patient___2Lm_n .index__Header___14zpe .index__Subtitle___2An3T {\n  font-size: 14px;\n  color: #000000;\n}\n.index__Patient___2Lm_n .index__Form___13khK {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.index__Patient___2Lm_n .index__Form___13khK > * {\n  margin-bottom: 16px;\n}\n.index__Patient___2Lm_n .index__Form___13khK > *:last-child {\n  margin-bottom: 0;\n}\n.index__Patient___2Lm_n .index__Form___13khK .index__Auxiliar___2IhSu, .index__Patient___2Lm_n .index__Form___13khK .index__AuxiliarError___Jigqm {\n  font-size: 14px;\n  color: #54565a;\n  align-self: center;\n}\n.index__Patient___2Lm_n .index__Form___13khK .index__AuxiliarError___Jigqm {\n  color: #A30B35;\n}\n.index__Patient___2Lm_n .index__Form___13khK .index__Input___2mfWw,\n.index__Patient___2Lm_n .index__Form___13khK .index__Button___3k9-Z {\n  width: 100%;\n  max-width: 280px;\n}\n.index__Patient___2Lm_n .index__Form___13khK .index__Button___3k9-Z {\n  color: white;\n}\n.index__Patient___2Lm_n .index__Form___13khK .index__Disclosure___3lSxA {\n  font-size: 12px;\n  color: #54565a;\n  text-align: center;\n}\n@media only screen and (max-width: 955px) {\n  .index__Patient___2Lm_n {\n    padding: 40px 30px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Patient": "index__Patient___2Lm_n",
	"Header": "index__Header___14zpe",
	"Title": "index__Title___2R93F",
	"Subtitle": "index__Subtitle___2An3T",
	"Form": "index__Form___13khK",
	"Auxiliar": "index__Auxiliar___2IhSu",
	"AuxiliarError": "index__AuxiliarError___Jigqm",
	"Input": "index__Input___2mfWw",
	"Button": "index__Button___3k9-Z",
	"Disclosure": "index__Disclosure___3lSxA"
};
module.exports = exports;
