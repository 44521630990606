//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { ChevronLeftIcon, ChevronRightIcon } from '@cmp/images';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Paginate extends React.Component {
  render() {
    const { className, onNext, onPrevious } = this.props;
    const componentClasses = classNames(styles.Paginate, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {onPrevious && (
          <ChevronLeftIcon
            className={styles.Icon}
            onClick={onPrevious}
          />
        )}
        {onNext && (
          <ChevronRightIcon
            className={styles.Icon}
            onClick={onNext}
          />
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Paginate;
