// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Details___3lCQg {\n  display: flex;\n  flex-direction: column;\n  max-width: 475px;\n  padding: 52px 58px;\n}\n.index__Details___3lCQg .index__BasicInfo___3dGYI {\n  margin-bottom: 22px;\n}\n.index__Details___3lCQg .index__Description___rxM8W {\n  font-size: 14px;\n  color: #54565a;\n}\n.index__Details___3lCQg .index__DroppedProducts___3me-j {\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n}\n.index__Details___3lCQg .index__DroppedProducts___3me-j > * {\n  margin-bottom: 10px;\n}\n.index__Details___3lCQg .index__DroppedProducts___3me-j > *:last-child {\n  margin-bottom: 0;\n}\n.index__Details___3lCQg .index__DroppedProducts___3me-j .index__Title___2V5b4 {\n  font-size: 14px;\n  color: #54565a;\n}\n.index__Details___3lCQg .index__Disclosure___z7av3 {\n  font-size: 14px;\n  color: #54565a;\n  text-align: center;\n  margin-top: 10px;\n}\n@media only screen and (max-width: 955px) {\n  .index__Details___3lCQg {\n    padding: 52px 24px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Details": "index__Details___3lCQg",
	"BasicInfo": "index__BasicInfo___3dGYI",
	"Description": "index__Description___rxM8W",
	"DroppedProducts": "index__DroppedProducts___3me-j",
	"Title": "index__Title___2V5b4",
	"Disclosure": "index__Disclosure___z7av3"
};
module.exports = exports;
