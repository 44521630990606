//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import clientStyles from '@style/common/client.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { Checkmark } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { addItem, withReferral } from '@helpers/referrals';
import { responseFormatter, shopifyFormatter } from '@helpers/formatter';
import { withTranslation } from 'react-i18next';
import { trackProductAddToCart } from '@helpers/tracking';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AddItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
    };
  }

  isAdded() {
    const { __referral, product } = this.props;
    return (
      __referral &&
      __referral.items &&
      __referral.items.findIndex(
        (x) => x.shopifyProductId.toString() === product.id.toString()
      ) > -1
    );
  }

  render() {
    let that = this;
    const { loading, errorMessage } = this.state;
    const { __referral, product, className,
      didAddItem, disableTabIndex, t } = this.props;
    const added = this.isAdded();

    const componentClasses = classNames(styles.AddItem, {
      [className]: className,
      [styles.Added]: added,
    });

    const defaultVariant = shopifyFormatter.product(product).defaultVariant();
    const isAvailable = shopifyFormatter.variant(defaultVariant).isAvailable();

    return (
      <div className={componentClasses}>
        {/* to-do: use a vector for the plus sign */}
        <Button
          style={ButtonStyle.Custom}
          className={styles.Button}
          loading={loading}
          disabled={added || !isAvailable}
          iconComponent={added && <Checkmark />}
          disableTabIndex ={disableTabIndex}
          onClick={() => {
            this.setState({ loading: true });

            addItem(product)
              .then(() => {
                trackProductAddToCart(product, 1);
                if (didAddItem) didAddItem(product);
              })
              .catch((err) => {
                const formattedError = responseFormatter.error(err);
                that.setState({ errorMessage: formattedError.message() });
                setTimeout(() => that.setState({ errorMessage: null }), 3000);
              })
              .finally(() => that.setState({ loading: false }));
          }}
        >
          {isAvailable
            ? added
              ? t('dashboard_Added')
              : `${t('dashboard_AddToList')}`
            : t('dashboard_SoldOut')}
        </Button>
        {errorMessage && (
          <span
            className={classNames(
              styles.ErrorMessage,
              clientStyles.ErrorMessage
            )}
          >
            {errorMessage}
          </span>
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withReferral(withTranslation()(AddItem));
