//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { CloseIcon } from '@cmp/images';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
export const DocsPage = {
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  DO_NOT_SELL: 'DO_NOT_SELL',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Docs extends React.Component {
  idealPage() {
    const { page, t } = this.props;

    switch (page) {
      case DocsPage.TERMS_OF_SERVICE:
        return { title: t('docs_TermsOfService'), screenSetId: 'terms-of-use' };

      case DocsPage.PRIVACY_POLICY:
        return {
          title: t('docs_PrivacyPolicy'),
          screenSetId: 'privacy-policy',
        };

      case DocsPage.DO_NOT_SELL:
        return {
          title: t('docs_DoNotSellPersonalData'),
          screenSetId: 'do-not-sell-my-personal-information',
        };

      default:
        return '';
    }
  }

  componentDidMount() {
    const idealPage = this.idealPage();

    if (idealPage.screenSetId) {
      gigya.accounts.showScreenSet({
        screenSet: idealPage.screenSetId,
        containerID: 'screen-set-container',
      });
    }
  }

  render() {
    const { page, history } = this.props;
    const componentClasses = classNames(styles.Docs);

    const idealPage = this.idealPage();

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{idealPage.title}</title>
        </Helmet>

        <div className={styles.Content}>
          <CloseIcon
            className={styles.Close}
            onClick={() =>
              history.length > 1 ? history.goBack() : window.close()
            }
          />
          <h1 className={styles.Title}>{idealPage.title}</h1>
          <div className={styles.TextContent} id="screen-set-container">
            {idealPage.__html && (
              <p
                className={styles.Text}
                dangerouslySetInnerHTML={idealPage}
              ></p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(withTranslation()(Docs));
