//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Data ------------------------------------------------------------------------
import Sections from './sections.js';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Form, Header, Button, ButtonStyle, Footer } from '@cmp/common';
import { PlusIcon, MinusIcon } from '@cmp/images';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { Products as ProductsRequest } from '@api/endpoints/get/products';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withAuthUser } from '@helpers/auth';
import { Trans, withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const HelpQuestionsId = {
  PRODUCT_LIST: 'product_list',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Help extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: Sections,
    };

    this.sectionComponent = this.sectionComponent.bind(this);
    this.questionComponent = this.questionComponent.bind(this);
  }

  headerComponent() {
    const { t } = this.props;
    return (
      <div className={styles.Header}>
        <h1 className={styles.Title}>{t('help_HowCanWeHelp')}</h1>
        <span className={styles.Subtitle}>
          {t('help_HowCanWeHelpSubtitle')}
        </span>
      </div>
    );
  }

  questionComponent(question, index) {
    let that = this;
    const { t } = this.props;
    const { sections } = this.state;

    const componentClasses = classNames(styles.Question, {
      [styles.QuestionExpanded]: question.expanded,
    });

    return (
      <div className={componentClasses} key={index} id={question.titleI18nKey}>
        <div
          className={styles.Title}
          onClick={() => {
            question.expanded = !question.expanded;
            that.setState({ sections });
          }}
        >
          {question.expanded ? (
            <MinusIcon className={styles.Icon} />
          ) : (
            <PlusIcon className={styles.Icon} />
          )}
          <span className={styles.Text}>{t(question.titleI18nKey)}</span>
        </div>
        <span className={styles.Answer}>
          <p
            className={styles.AnswerContent}
            dangerouslySetInnerHTML={{ __html: t(question.answerI18nKey) }}
          ></p>
          {this.questionAdditionalComponent(question)}
        </span>
      </div>
    );
  }

  questionAdditionalComponent(question) {
    if (question.id !== HelpQuestionsId.PRODUCT_LIST) return;

    return (
      <ProductsRequest params={{ limit: 200 }}>
        {({ data }) => {
          if (!data || !data.products) return '';

          const { products } = data;

          return (
            <ul className={styles.ProductList}>
              {products.map((x, index) => (
                <li key={index}>
                  <Link
                    to={`/product/${x.id}`}
                    target="_blank"
                    className={styles.Link}
                  >
                    {x.title}
                  </Link>
                </li>
              ))}
            </ul>
          );
        }}
      </ProductsRequest>
    );
  }

  sectionComponent(section, index) {
    const { __authUser, t } = this.props;
    if (section.requiresAuthentication && !__authUser) return '';

    return (
      <div className={styles.Section} key={index} id={section.titleI18nKey}>
        <span className={styles.Title}>{t(section.titleI18nKey)}</span>
        {section.questions.map(this.questionComponent)}
      </div>
    );
  }

  sectionsComponent() {
    const { sections } = this.state;

    return (
      <div className={styles.Sections}>
        {sections.map(this.sectionComponent)}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.Help);

    return (
      <>
        <div className={componentClasses}>
          <Helmet>
            <title>{t('help_Help')}</title>
          </Helmet>

          <Header />
          <div className={styles.Content}>
            {this.headerComponent()}
            {this.sectionsComponent()}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(Help));
