//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { referralFormatter } from '@helpers/formatter';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Success extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal = (path) => {
    // Hack: in order to properly update the recommendation history page,
    //       force a full refresh
    const {
      location: { state },
    } = this.props;

    const recommendationsPath = `/${path}`;
    window.location =
      recommendationsPath ||
      (state && state.from && state.from.pathname) ||
      '/';
  };

  render() {
    const {
      location: { state },
    } = this.props;
    const { user, referral } = state || {};

    const componentClasses = classNames(styles.Success);
    const totalRecommendedCount = referralFormatter.totalRecommendedCount(
      referral
    );

    const recomendationCopy =
      totalRecommendedCount > 1
        ? 'dashboard_SuccessfullyRecommended_plural'
        : 'dashboard_SuccessfullyRecommended';
    return (
      <Modal.Generic
        title={t('dashboard_RecommendationSent')}
        subtitle={t(recomendationCopy, {
          count: totalRecommendedCount,
          name: user.name || t('dashboard_APatient'),
        })}
        action={t('dashboard_ViewRecommendation')}
        actionStyle={ButtonStyle.ModalButton}
        to="/recommendations"
        onClick={() => this.closeModal('recommendations')}
        onClose={() => this.closeModal('')}
      >
        <Helmet>
          <title>{t('dashboard_Success')}</title>
        </Helmet>
      </Modal.Generic>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Success);
