//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, Logo, ViewRecommendationsButton } from '@cmp/common';
import { Menu } from './common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import {
  dashboardIconUrl,
  shoppingCartIconUrl,
  downChevronIconUrl,
  MenuIcon,
} from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withAuthUser } from '@helpers/auth';
import { withReferral } from '@helpers/referrals';
import { gigyaFormatter } from '@helpers/formatter';
import { hasGigyaLoggedIn } from '@helpers/account';
import { deauth } from '@helpers/auth';
import { getValue } from '@helpers/localStorage';
// API -------------------------------------------------------------------------
import { Providers as ProvidersRequest } from '@api/endpoints/get/providers';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { PayoutOption } from '@helpers/constants/user';
import { refCode } from '@helpers/constants/localStorage';
import { withTranslation } from 'react-i18next';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isMenuVisible: null,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { anchorEl } = this.state;

    if (
      anchorEl &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.setState({ anchorEl: null });
    }
  }

  pagesComponent() {
    const { __authUser, t, i18n } = this.props;

    return (
      <div className={styles.Menu}>
        <span className={styles.MenuTitle}>EltaMD ProCart</span>
        {__authUser && hasGigyaLoggedIn(__authUser) && (
          <div className={styles.Options}>
            {this.menuOptionComponent(
              dashboardIconUrl(i18n),
              t('header_Dashboard'),
              '/recommendations',
              t('alt_header_dashboard_icon'),
            )}
            {this.menuOptionComponent(
              shoppingCartIconUrl(i18n),
              t('header_RecommendedProducts'),
              '/',
              t('alt_header_dashboard_cart'),
            )}
          </div>
        )}
      </div>
    );
  }

  menuOptionComponent(icon, title, to, alt) {
    return (
      <NavLink
        exact
        className={styles.MenuOption}
        activeClassName={styles.MenuOptionActive}
        to={to}
      >
        <img className={styles.Icon} src={icon} alt={alt}/>
        <span className={styles.Title}>{title}</span>
      </NavLink>
    );
  }

  recommendationsComponent() {}

  userInfoComponent() {
    const { __authUser, i18n, t } = this.props;

    return (
      <div className={styles.UserInfo} ref={(node) => (this.wrapperRef = node)}>
        <div
          tabIndex={0}
          className={styles.UserName}
          onClick={({ target }) => this.setState({ anchorEl: target })}
          onKeyDown={(target) => {
            if (target.key==='Enter') this.setState({ anchorEl: target })
          }}
        >
          {__authUser && (
            <span className={styles.Name}>
              {gigyaFormatter.fullName(__authUser)}
            </span>
          )}
          <img
            alt={t('alt_chevron')}
            src={downChevronIconUrl(i18n)}
            className={styles.DownChevron} />
        </div>
        {this.allPagesComponent()}
      </div>
    );
  }

  providerCodeComponent() {
    const { __authUser, location, t } = this.props;
    const { anchorEl } = this.state;
    const componentClasses = classNames(styles.Menu, {
      [styles.Options]: !anchorEl,
    });
    const referralCode = getValue(refCode);
    const profCodeEnabled = false;

    return (
      <div>
        {profCodeEnabled && (
          <RouterLink
            to={{
              pathname: '/recommendation/professional-code',
              state: { from: location, modal: true },
            }}
            onClick={() => this.setState({ anchorEl: null })}
            className={styles.MenuOption}
          >
            <span className={styles.Title}>
              {t('header_ShareYourCode', { code: referralCode })}
            </span>
          </RouterLink>
        )}
      </div>
    );
  }

  allPagesComponent() {
    const { __authUser, location, t } = this.props;
    const { anchorEl } = this.state;
    const componentClasses = classNames(styles.UserInfoMenu, {
      [styles.Hidden]: !anchorEl,
    });

    const HeaderPages = [
      {
        i18nTitleKey: 'header_Account',
        pathname: '/myaccount',
        requiresAuthentication: true
      },
      // TODO: show about page
      // {
      //   i18nTitleKey: 'header_About',
      //   pathname: '/about',
      // },
      {
        i18nTitleKey: 'header_Help',
        pathname: '/help',
      },
      {
        i18nTitleKey: 'header_Logout',
        onClick: () => deauth(),
        requiresAuthentication: true,
      },
    ];

    return (
      <div
        className={componentClasses}
        onBlur={(e) => {
          if(e.target.innerHTML=== 'Logout')
            this.setState({anchorEl:null})
        }}>
        {HeaderPages.map((item) => {
          if (
            item.requiresCompleteRegistration &&
            (!__authUser || !hasGigyaLoggedIn(__authUser))
          ) {
            return null;
          }

          if (item.requiresAuthentication && !__authUser) {
            return null;
          }

          if (item.pathname) {
            return (
              <Link
                to={item.pathname}
                className={styles.MenuOption}
                onClick={() => this.setState({ anchorEl: null })}
                key={item.i18nTitleKey}
              >
                {t(item.i18nTitleKey)}
              </Link>
            );
          }

          return (
            <span
              className={styles.MenuOption}
              onClick={item.onClick}
              key={item.i18nTitleKey}
              tabIndex={0}
            >
              {t(item.i18nTitleKey)}
            </span>
          );
        })}
      </div>
    );
  }

  responsiveMenuComponent() {
    const { location } = this.props;
    const { isMenuVisible } = this.state;

    return (
      <div className={styles.ResponsiveMenu}>
        <MenuIcon
          className={styles.Icon}
          onClick={() => this.setState({ isMenuVisible: true })}
        />
        <Menu
          isVisible={isMenuVisible}
          location={location}
          onClose={() => this.setState({ isMenuVisible: false })}
        />
      </div>
    );
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.Header, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <div className={styles.Content}>
          <div className={styles.Left}>
            <Logo className={styles.Logo} />
            {this.pagesComponent()}
          </div>
          <div className={styles.Right}>
            <ViewRecommendationsButton className={styles.Recommendations} />
            {this.userInfoComponent()}
            {this.responsiveMenuComponent()}
          </div>
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(
  withAuthUser(withReferral(withTranslation()(Header)))
);
