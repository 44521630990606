//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { isAndroid, isIOS } from 'react-device-detect';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Modal, Popover, Footer } from '@cmp/common';
import {
  Recommendations,
  Products,
  Product,
  Patient,
  Recommendation,
  Success,
  Media,
  ProfessionalCode,
  ProfessionalCodeSuccess,
} from './client';
import Payment from '../callback/client/payment';
//------------------------------------------------------------------------------
// Local Storage ---------------------------------------------------------------
import { getValue, storeValue } from '@helpers/localStorage';
import {
  WelcomeStorageKey,
  WelcomeShortcutStorageKey,
} from '@helpers/constants/localStorage';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { loadClinics } from '@api/endpoints/get/providers';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withModal } from '@helpers/modal';
import { Trans, withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Dashboard extends React.Component {
  componentDidMount() {
    this.pushAuxiliarRoutesIfNeeded();
  }

  pushAuxiliarRoutesIfNeeded() {
    // Recommendations ---------------------------------------------------------
    loadClinics().then(({ data }) => {
      let pushWelcomeRoute = false;

      if (data.recommendationCount <= 0) {
        pushWelcomeRoute = this.pushRouteIfNeeded(
          '/welcome',
          WelcomeStorageKey
        );
      }

      if (pushWelcomeRoute && (isAndroid || isIOS)) {
        this.pushRouteIfNeeded('/shortcut', WelcomeShortcutStorageKey);
      }
    });
  }

  pushRouteIfNeeded(pathname, key) {
    const { history, location } = this.props;
    const shownRoute = getValue(key);

    if (!shownRoute) {
      history.push({
        pathname,
        state: { from: location, modal: true },
      });

      storeValue(key, true);
    }

    return shownRoute;
  }

  render() {
    const { from, isModal, t } = this.props;
    const componentClasses = classNames(styles.Dashboard);

    

    return (
      <div className={componentClasses}>
        {<Header />}
        <div className={styles.Content}>
          <Switch>
            {isModal && (
              <Route exact path="/recommendation/patient" component={Patient} />
            )}
            {isModal && (
              <Route
                exact
                path="/recommendation/professional-code"
                component={ProfessionalCode}
              />
            )}
            {isModal && (
              <Route exact path="/recommendation/success" component={Success} />
            )}
            {isModal && (
              <Route
                exact
                path="/recommendation/professional-code-success"
                component={ProfessionalCodeSuccess}
              />
            )}
            {isModal && (
              <Route
                path="/recommendation/:id"
                render={(props) => (
                  <Recommendation isModal={isModal} {...props} />
                )}
              />
            )}
            {isModal && (
              <Route
                exact
                path="/welcome"
                render={(props) => (
                  <Modal.Generic
                    title={t('dashboard_Congratulations')}
                    subtitle={t('dashboard_CongratulationsSubtitle')}
                    action={t('dashboard_CreateNewRecommendation')}
                    {...props}
                  />
                )}
              />
            )}
            {isModal && (
              <Route
                exact
                path="/shortcut"
                render={(props) => (
                  <Popover.Generic
                    title={t('dashboard_AddShortcut')}
                    subtitle={
                      <Trans i18nKey="dashboard_AddShortcutSubtitle">
                        Create a shortcut to Elta ProCart in your phone home
                        screen for quick access.{' '}
                        <Link to="/help#shortcut">Click here</Link> to learn
                        more.
                      </Trans>
                    }
                    auxiliar={t('dashboard_AddShortcutAux')}
                    {...props}
                  />
                )}
              />
            )}
            {isModal && <Route exact path="/media" component={Media} />}
          </Switch>
          <Switch location={from}>
            {/* Recommendations */}
            <Route
              exact
              path="/recommendations"
              render={(props) => (
                <Recommendations
                  {...props}
                  fullScreen={(fullScreen) => this.setState({ fullScreen })}
                />
              )}
            />

            {/* Products */}
            <Route path="/product/:id" component={Product} />
            <Route exact path="/" component={Products} />
            <Route exact path="/welcome" component={Recommendations} />

            {/* Generic Redirect */}
            <Redirect from="*" to="/" />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(withModal(Dashboard));
