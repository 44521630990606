import React from 'react';
import { withTranslation } from 'react-i18next';
import BannerImageResponsiveEn from '@img/landing/banner/banner_responsive@2x.jpg';
import BannerImageEn from '@img/landing/banner/banner_new@2x.jpg';
import {
  AboutBackground as AboutBackgroundEn,
  PatientBenefit as PatientBenefitEn,
  PatientBenefitResponsive as PatientBenefitResponsiveEn,
} from '@img/about';
import { ChevronUpFilled as ChevronUpFilledEn } from '@img/products';
import {
  ChevronLeftIcon as ChevronLeftIconEn,
  ChevronRightIcon as ChevronRightIconEn,
} from '@img/dashboard';
import { SearchIcon as SearchIconEn } from '@img/dashboard';
import LogoPlaceholderEn from '@img/logo/logo_placeholder.svg';
import {
  MinusIcon as MinusIconEn,
  PlusIcon as PlusIconEn,
} from '@img/recommendation';
import {
  DollarSignIcon as DollarSignIconEn,
  GiftCardIcon as GiftCardIconEn,
} from '@img/dashboard';
import {
  LifelineIcon as LifelineIconEn,
  FlagIcon as FlagIconEn,
  PurchasesIcon as PurchasesIconEn,
} from '@img/dashboard';
import CloseIconEn from '@img/docs/close.svg';
import { default as FirstTileEn } from '@img/landing/products/tile_01@2x.jpg';
import { default as SecondTileEn } from '@img/landing/products/tile_02@2x.jpg';
import { default as ThirdTileEn } from '@img/landing/products/tile_03@2x.jpg';
import PatientSatisfactionImageEn from '@img/landing/patient_satisfaction/patient_satisfaction@2x.png';
import { Checkmark as CheckmarkEn } from '@img/common';
import ChecklistIconEn from '@img/common/checklist.svg';
import { PlayIcon as PlayIconEn } from '@img/common';
import LogoImageEn from '@img/logo/elta_logo.png';
import {
  AwardIcon as AwardIconEn,
  SendIcon as SendIconEn,
  ShoppingBagIcon as ShoppingBagIconEn,
} from '@img/landing/how_it_works';
import MenuIconEn from '@img/common/menu.svg';
import {
  DashboardIcon as DashboardIconEn,
  ShoppingCartIcon as ShoppingCartIconEn,
  DownChevronIcon as DownChevronIconEn,
} from '@img/dashboard';

function getUrl(cfg, lang) {
  switch (lang) {
    default:
      return cfg['en'];
  }
}

export const BannerImageResponsive = withTranslation()(
  function BannerImageResponsive({ i18n, t, tReady, ...props }) {
    return (
      <img
        src={getUrl(
          {
            en: BannerImageResponsiveEn,
          },
          i18n.language
        )}
        alt={t('alt_hero')}
        {...props}
      />
    );
  }
);

export const BannerImage = withTranslation()(function BannerImage({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: BannerImageEn,
        },
        i18n.language
      )}
      alt={t('alt_hero')}
      {...props}
    />
  );
});

export const AboutBackground = withTranslation()(function AboutBackground({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: AboutBackgroundEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const PatientBenefit = withTranslation()(function PatientBenefit({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: PatientBenefitEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const PatientBenefitResponsive = withTranslation()(
  function PatientBenefitResponsive({ i18n, t, tReady, ...props }) {
    return (
      <img
        src={getUrl(
          {
            en: PatientBenefitResponsiveEn,
          },
          i18n.language
        )}
        {...props}
      />
    );
  }
);

export const ChevronUpFilled = withTranslation()(function ChevronUpFilled({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: ChevronUpFilledEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const MinusIcon = withTranslation()(function MinusIcon({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: MinusIconEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const PlusIcon = withTranslation()(function PlusIcon({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: PlusIconEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const ChevronLeftIcon = withTranslation()(function ChevronLeftIcon({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: ChevronLeftIconEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const ChevronRightIcon = withTranslation()(function ChevronRightIcon({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: ChevronRightIconEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const CloseIcon = withTranslation()(function CloseIcon({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: CloseIconEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const Checkmark = withTranslation()(function Checkmark({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: CheckmarkEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const ChecklistIcon = withTranslation()(function ChecklistIcon({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: ChecklistIconEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const PlayIcon = withTranslation()(function PlayIcon({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: PlayIconEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export const LogoImage = withTranslation()(function LogoImage({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: LogoImageEn,
        },
        i18n.language
      )}
      alt={t('alt_logo')}
      {...props}
    />
  );
});

export const MenuIcon = withTranslation()(function MenuIcon({
  i18n,
  t,
  tReady,
  ...props
}) {
  return (
    <img
      src={getUrl(
        {
          en: MenuIconEn,
        },
        i18n.language
      )}
      {...props}
    />
  );
});

export function searchIconUrl(i18n) {
  return getUrl(
    {
      en: SearchIconEn,
    },
    i18n.language
  );
}

export function logoPlaceholderUrl(i18n) {
  return getUrl(
    {
      en: LogoPlaceholderEn,
    },
    i18n.language
  );
}

export function dollarSignIconUrl(i18n) {
  return getUrl(
    {
      en: DollarSignIconEn,
    },
    i18n.language
  );
}

export function giftCardIconUrl(i18n) {
  return getUrl(
    {
      en: GiftCardIconEn,
    },
    i18n.language
  );
}

export function lifelineIconUrl(i18n) {
  return getUrl(
    {
      en: LifelineIconEn,
    },
    i18n.language
  );
}

export function flagIconUrl(i18n) {
  return getUrl(
    {
      en: FlagIconEn,
    },
    i18n.language
  );
}

export function purchasesIconUrl(i18n) {
  return getUrl(
    {
      en: PurchasesIconEn,
    },
    i18n.language
  );
}

export function patientSatisfactionImageUrl(i18n) {
  return getUrl(
    {
      en: PatientSatisfactionImageEn,
    },
    i18n.language
  );
}

export function awardIconUrl(i18n) {
  return getUrl(
    {
      en: AwardIconEn,
    },
    i18n.language
  );
}

export function sendIconUrl(i18n) {
  return getUrl(
    {
      en: SendIconEn,
    },
    i18n.language
  );
}

export function shoppingBagIconUrl(i18n) {
  return getUrl(
    {
      en: ShoppingBagIconEn,
    },
    i18n.language
  );
}

export function dashboardIconUrl(i18n) {
  return getUrl(
    {
      en: DashboardIconEn,
    },
    i18n.language
  );
}

export function shoppingCartIconUrl(i18n) {
  return getUrl(
    {
      en: ShoppingCartIconEn,
    },
    i18n.language
  );
}

export function downChevronIconUrl(i18n) {
  return getUrl(
    {
      en: DownChevronIconEn,
    },
    i18n.language
  );
}

export function tilesUrls(i18n) {
  return [
    getUrl(
      {
        en: FirstTileEn,
      },
      i18n.language
    ),
    getUrl(
      {
        en: SecondTileEn,
      },
      i18n.language
    ),
    getUrl(
      {
        en: ThirdTileEn,
      },
      i18n.language
    ),
  ];
}
