//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import qs from 'query-string';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Badge, Button, ButtonStyle } from '@cmp/common';
import { BasicInfo, Products, CheckoutDetails } from '../../common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
  withReferral,
  loadReferralAndStore,
  updateItem,
  removeItem,
} from '@helpers/referrals';
import { referralFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Local Storage ---------------------------------------------------------------
import { getValue } from '@helpers/localStorage';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { createRecommendation } from '@api/endpoints/post/recommendation';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Review extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingProducts: [],
    };
    this.onEditProduct = this.onEditProduct.bind(this);
  }

  componentDidMount() {
    loadReferralAndStore();
  }

  componentDidUpdate() {
    const { __referral, location, history } = this.props;

    if (__referral && __referral.items.length <= 0) {
      history.push(location.state.from);
    }
  }

  onEditProduct(product, quantity) {
    let that = this;

    const { __referral, history } = this.props;
    const { editingProducts } = this.state;
    editingProducts.push(product);
    this.setState({ editingProducts });

    function removeEditingProduct() {
      const { editingProducts } = that.state;
      const filtered = editingProducts.filter((x) => x !== product);
      that.setState({ editingProducts: filtered });
    }

    if (quantity > 0) {
      updateItem(product, quantity).finally(() => removeEditingProduct());
    } else {
      removeItem(product).finally(() => removeEditingProduct());
    }
    return true
  }

  render() {
    const { editingProducts } = this.state;
    const { __referral, location, history, t } = this.props;
    if (!__referral) return '';

    const componentClasses = classNames(styles.Details);

    return (
      <Modal.Base
        className={componentClasses}
        onClose={() => history.push(location.state.from)}
      >
        <Helmet>
          <title>{t('dashboard_ReviewYourRecommendation')}</title>
        </Helmet>

        <BasicInfo
          title={t('dashboard_ReviewRecommendation')}
          subtitleComponent={
            <span className={styles.ReviewSubtitle}>
              {t('dashboard_ReviewYourRecommendationSubtitle')}
            </span>
          }
          className={styles.BasicInfo}
        />
        <Products
          products={referralFormatter.sortedItems(__referral)}
          onEditProduct={this.onEditProduct}
          editable={true}
          editingProducts={editingProducts}
        />
        <CheckoutDetails
          action={t('dashboard_SendRecommendation')}
          referral={__referral}
          onClick={() => {
            const pathname = '/recommendation/patient';
            history.push({
              pathname: pathname,
              state: { from: location.state.from, modal: true, __referral },
            });
          }}
          disabled={!__referral.items || __referral.items.length <= 0}
        />
        <span className={styles.Disclosure}>
          {t('dashboard_RecommendationDisclosure')}
        </span>
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withReferral(withTranslation()(Review));
