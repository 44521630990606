// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__RegistrationComplete___11NAM {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  background-color: #fafafb;\n  height: 100%;\n  padding-top: 100px;\n  overflow: scroll;\n}\n.index__RegistrationComplete___11NAM .index__Form___3amdn {\n  max-width: 716px;\n}\n.index__RegistrationComplete___11NAM .index__Form___3amdn .index__Text___3hIzr {\n  font-size: 14px;\n  color: #8e8e8e;\n  font-weight: bold;\n}\n@media only screen and (max-width: 955px) {\n  .index__RegistrationComplete___11NAM .index__Form___3amdn {\n    width: 90%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"RegistrationComplete": "index__RegistrationComplete___11NAM",
	"Form": "index__Form___3amdn",
	"Text": "index__Text___3hIzr"
};
module.exports = exports;
