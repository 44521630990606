//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { BannerImageResponsive, BannerImage } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers ----------------------------------------------------------------------
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Banner extends React.Component {
  render() {
    const componentClasses = classNames(styles.Banner);
    const { t } = this.props;

    return (
      <div className={componentClasses}>
        <BannerImage
          className={classNames(
            styles.BackgroundImage,
            styles.BackgroundImageDesktop
          )}
        />
        <BannerImageResponsive
          className={classNames(
            styles.BackgroundImage,
            styles.BackgroundImageResponsive
          )}
        />
        <div className={styles.Subcontent}>
          <div className={styles.Text}>
            <h1 className={styles.Title}>
              {t('landing_MakeRecommendationsReality')}
            </h1>
          </div>
          <div className={styles.ResponsiveHeader}>
            <span className={styles.Title}>{t('landing_GetStarted')}</span>
          </div>
          <Link
            to="/signup"
            className={styles.Link}
            style={{ textTransform: 'uppercase' }}
          >
            {t('landing_CreateAccount')}
          </Link>
          <Link
            to="/login"
            className={styles.Link}
            style={{ textTransform: 'uppercase' }}
          >
            {t('landing_LogIn')}
          </Link>
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Banner);
