// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Breadcrumbs___1Hj7O {\n  display: flex;\n  align-items: baseline;\n  flex-wrap: wrap;\n}\n.index__Breadcrumbs___1Hj7O > * {\n  margin-right: 3px;\n}\n.index__Breadcrumbs___1Hj7O > *:last-child {\n  margin-right: 0;\n}\n.index__Breadcrumbs___1Hj7O .index__Page___1j1Gv {\n  display: flex;\n  align-items: baseline;\n  font-weight: bold;\n  font-size: 14px;\n}\n.index__Breadcrumbs___1Hj7O .index__Page___1j1Gv > * {\n  margin-right: 3px;\n}\n.index__Breadcrumbs___1Hj7O .index__Page___1j1Gv > *:last-child {\n  margin-right: 0;\n}\n.index__Breadcrumbs___1Hj7O .index__Page___1j1Gv .index__Title___3zN_L,\n.index__Breadcrumbs___1Hj7O .index__Page___1j1Gv .index__Separator___1ZV64 {\n  color: #54565a;\n}\n.index__Breadcrumbs___1Hj7O .index__Page___1j1Gv .index__Title___3zN_L.index__Current___3EtTM,\n.index__Breadcrumbs___1Hj7O .index__Page___1j1Gv .index__Separator___1ZV64.index__Current___3EtTM {\n  color: black;\n}", ""]);
// Exports
exports.locals = {
	"Breadcrumbs": "index__Breadcrumbs___1Hj7O",
	"Page": "index__Page___1j1Gv",
	"Title": "index__Title___3zN_L",
	"Separator": "index__Separator___1ZV64",
	"Current": "index__Current___3EtTM"
};
module.exports = exports;
