//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SearchInput extends React.Component {
  render() {
    const { className, value, placeholder, icon, onChange, t } = this.props;
    const componentClasses = classNames(styles.SearchInput, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <img src={icon} className={styles.Icon} alt={t('alt_search_input_icon')} />
        <form>
          <input
            value={value}
            placeholder={placeholder}
            onChange={({ currentTarget: { value } }) => onChange(value)}
            className={styles.Input}
          />
        </form>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SearchInput;
