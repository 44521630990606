// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Details___1W824 {\n  display: flex;\n  flex-direction: column;\n  max-width: 675px;\n  padding: 52px 58px;\n}\n.index__Details___1W824 .index__BasicInfo___jyWjD {\n  margin-bottom: 22px;\n}\n.index__Details___1W824 .index__Description___2l5Dc {\n  font-size: 14px;\n  color: #54565a;\n}\n.index__Details___1W824 .index__DroppedProducts___1ajKV {\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n}\n.index__Details___1W824 .index__DroppedProducts___1ajKV > * {\n  margin-bottom: 10px;\n}\n.index__Details___1W824 .index__DroppedProducts___1ajKV > *:last-child {\n  margin-bottom: 0;\n}\n.index__Details___1W824 .index__DroppedProducts___1ajKV .index__Title___MY-A1 {\n  font-size: 14px;\n  color: #54565a;\n}", ""]);
// Exports
exports.locals = {
	"Details": "index__Details___1W824",
	"BasicInfo": "index__BasicInfo___jyWjD",
	"Description": "index__Description___2l5Dc",
	"DroppedProducts": "index__DroppedProducts___1ajKV",
	"Title": "index__Title___MY-A1"
};
module.exports = exports;
