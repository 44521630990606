import { referralFormatter } from '@helpers/formatter';

const currency = "USD";

const convertToGTMProduct = (
  product,
  item_list_id,
  item_list_name,
  index,
  quantity = 1,
) => {
  return {
    item_id: product.variants[0].product_id,
    item_name: product.title,
    discount:
      product.compareAtPriceRange?.minVariantPrice?.amount &&
      product.compareAtPriceRange?.minVariantPrice?.amount > 0
        ? product.compareAtPriceRange?.minVariantPrice?.amount -
          product.priceRange?.minVariantPrice?.amount
        : 0,
    index,
    item_brand: product.vendor ?? "",
    item_category: product.productType ?? "",
    item_list_id,
    item_list_name,
    price: product.variants && product.variants[0] && product.variants[0].price || product.discountedPrice,
    quantity,
  };
};

const convertToGTMProductFromLineItem = (lineItem, quantity = 1) => {
  return {
    item_id: lineItem.shopifyProductId,
    item_name: lineItem.productName,
    discount: lineItem.fullPrice - lineItem.discountedPrice,
    price: lineItem.discountedPrice,
    item_category:  "",
    item_brand:  "",
    quantity,
    index : 0,
  };
};

/**
 * Tracks the event of user viewing products on a PLP/CLP.
 * GTM event name: "view_item_list"
 *
 * @param {Product[]} products - Products to track in GTM
 * @param {string} item_list_id - Id of the page context where the item was seen
 * @param {string} item_list_name - Name of the page context where the item was seen
 * @param {number} [indexOffset] - How much to offset the products' position in the collection,
 * useful for paginated content. Default is 0.
 */
export const trackProductsViewed = (
  products,
  item_list_id,
  item_list_name,
  indexOffset = 0,
) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      item_list_id,
      item_list_name,
      items: products.map((product, index) => {
        return convertToGTMProduct(
          product,
          item_list_id,
          item_list_name,
          indexOffset + index + 1,
        );
      }),
    },
  });
};

/**
 * Tracks the event of user clicking on a product card.
 * GTM event name: "select_item"
 *
 * @param {Product} product - Product to track in GTM
 * @param {string} item_list_id - Id of the page context where the item was seen
 * @param {string} item_list_name - Name of the page context where the item was seen
 * @param {number} [index] - Index of the product in the collection.
 */
export const trackProductClicked = (
  product,
  item_list_id,
  item_list_name,
  index = 0,
) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "select_item",
    item_list_id,
    item_list_name,
    items: [
      convertToGTMProduct(product, item_list_id, item_list_name, index++),
    ],
  });
};

/**
 * Tracks the event of user viewing a product on the PDP.
 * GTM event name: "view_item"
 *
 * @param {Product} product - Product to track in GTM
 */
export const trackProductViewed = (product) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "view_item",
    ecommerce: {
      currency,
      value: parseFloat(
        product.priceRange?.minVariantPrice?.amount.toString() ?? 0,
      ),
      items: [convertToGTMProduct(product, "", "", 0)],
    },
  });
};

/**
 * Tracks the event of user adding a product to cart in GTM.
 * GTM event name: "add_to_cart"
 *
 * @param {CmsProductAtcInfo} productInfo - Minimal product info to track ATC event
 */
export const trackProductAddToCart = (product, quantity) => {    
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency,
      value:product.variants[0].price * quantity,
      items: [convertToGTMProduct(product, "", "", 0, quantity)],
    },
  });
};

/**
 * Tracks the event of user adding a product to cart in GTM.
 * GTM event name: "add_to_cart"
 *
 * @param {CmsProductAtcInfo} productInfo - Minimal product info to track ATC event
 */
export const trackProductAddFromCart = (lineItem) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency,
      value: lineItem.discountedPrice,
      items: [convertToGTMProductFromLineItem(lineItem)],
    },
  });
};

/**
 * Tracks the event of user removing a product from cart in GTM.
 * GTM event name: "remove_from_cart"
 *
 * @param {CmsProductAtcInfo} productInfo - Minimal product info to track Remove From Cart event
 */
export const trackProductRemoveFromCart = (lineItem, quanity = 1) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      currency,
      value: lineItem.discountedPrice,
      items: [convertToGTMProductFromLineItem(lineItem,quanity)],
    },
  });
};

/**
 * Tracks the event of user viewing cart in GTM.
 * GTM event name: "view_cart"
 *
 * @param {Cart} cart - cart object
 */
export const trackViewCart = (cart) => {
  const finalPrice = referralFormatter.finalPrice(cart);
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "view_cart",
    ecommerce: {
      currency,
      value: finalPrice.subtotal,
      coupon: "",
      items:
        cart?.items?.map((item, key) => {
          return convertToGTMProductFromLineItem(item, key);
        }) ?? [],
    },
  });
};

export const trackBeginCheckout = (cart) => {
  const finalPrice = referralFormatter.finalPrice(cart);
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      currency,
      value: finalPrice.subtotal,
      coupon: "",
      items:
        cart?.items?.map((item, key) => {
          return convertToGTMProductFromLineItem(item, key);
        }) ?? [],
    },
  });
};


export const trackPurchase = (cart) => {
    const finalPrice = referralFormatter.finalPrice(cart);
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "purchase",
        ecommerce: {
            currency,
            value: finalPrice.subtotal,
            coupon: "",
            shipping: 0,
            transaction_id: cart.id,
            tax: 0,
            total_value: finalPrice.subtotal,
            items:
                cart?.items?.map((item, key) => {
                    return convertToGTMProductFromLineItem(item, key);
                }) ?? [],
        },
    });


}

export default {
  trackProductsViewed,
  trackProductClicked,
  trackProductViewed,
  trackProductAddToCart,
  trackProductRemoveFromCart,
  trackViewCart,
  trackBeginCheckout,
  trackPurchase,
  trackProductAddFromCart
};
