// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Form___TbQmb {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background: #ffffff;\n  max-width: 508px;\n  padding: 42px 80px;\n  text-align: center;\n}\n.index__Form___TbQmb .index__Title___3mpC_ {\n  font-weight: bold;\n  font-size: 24px;\n  margin-bottom: 11px;\n}\n.index__Form___TbQmb .index__Subtitle___2KLVF {\n  font-weight: normal;\n  font-size: 14px;\n  margin-bottom: 32px;\n}\n.index__Form___TbQmb .index__Content___3s_53 {\n  align-self: stretch;\n  min-width: 350px;\n}\n.index__Form___TbQmb .index__Content___3s_53 > * {\n  margin-bottom: 16px;\n}\n.index__Form___TbQmb .index__Content___3s_53 > *:last-child {\n  margin-bottom: 0;\n}\n.index__Form___TbQmb .index__Submit___1I4Vm {\n  width: 100%;\n  background-color: #A30B35;\n  background: #A30B35 0% 0% no-repeat padding-box;\n  color: #FFFFFF;\n}\n.index__Form___TbQmb .index__ErrorMessage___2MURy {\n  color: #54565a;\n  font-size: 12px;\n  font-weight: bold;\n  margin-top: 12px;\n  cursor: default;\n}\n@media only screen and (max-width: 955px) {\n  .index__Form___TbQmb {\n    padding: 22px 30px;\n    max-width: none;\n  }\n  .index__Form___TbQmb .index__Content___3s_53 {\n    min-width: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Form": "index__Form___TbQmb",
	"Title": "index__Title___3mpC_",
	"Subtitle": "index__Subtitle___2KLVF",
	"Content": "index__Content___3s_53",
	"Submit": "index__Submit___1I4Vm",
	"ErrorMessage": "index__ErrorMessage___2MURy"
};
module.exports = exports;
