//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { storeValue, getValue, removeItem } from '@helpers/localStorage';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import {
    UserStorageKey,
    AuthorizationStorageKey
} from '@helpers/constants/localStorage';
//------------------------------------------------------------------------------
// Exports ---------------------------------------------------------------------
// If we received user data from Gigya AND we have a Bearer Token from PRP.
export const hasGigyaLoggedIn = (user) => (user && user.isRegistered && user.data);

export const hasGigyaLoggedInAndBearerToken = (user) =>
    (hasGigyaLoggedIn(user) && getValue(AuthorizationStorageKey, false))

export const getAspCode = () => {
    try {
        const colgateUser = getValue(UserStorageKey, true);
        return colgateUser.data.aspCode
    } catch (e) {
        console.error(e);
        return null;
    }
};
