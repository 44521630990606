// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Paginate___3rcyo {\n  display: flex;\n}\n.index__Paginate___3rcyo > * {\n  margin-right: 10px;\n}\n.index__Paginate___3rcyo > *:last-child {\n  margin-right: 0;\n}\n.index__Paginate___3rcyo .index__Icon___3vS8j {\n  cursor: pointer;\n  padding: 10px;\n}\n.index__Paginate___3rcyo .index__Icon___3vS8j:hover {\n  opacity: 0.8;\n}", ""]);
// Exports
exports.locals = {
	"Paginate": "index__Paginate___3rcyo",
	"Icon": "index__Icon___3vS8j"
};
module.exports = exports;
