// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Details___3AX-7 {\n  display: flex;\n  flex-direction: column;\n  max-width: 675px;\n  padding: 52px 58px;\n}\n.index__Details___3AX-7 .index__BasicInfo___2LnYf {\n  margin-bottom: 22px;\n}\n.index__Details___3AX-7 .index__Description___1b1VL {\n  font-size: 14px;\n  color: #54565a;\n}\n.index__Details___3AX-7 .index__DroppedProducts___3Npfo {\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n}\n.index__Details___3AX-7 .index__DroppedProducts___3Npfo > * {\n  margin-bottom: 10px;\n}\n.index__Details___3AX-7 .index__DroppedProducts___3Npfo > *:last-child {\n  margin-bottom: 0;\n}\n.index__Details___3AX-7 .index__DroppedProducts___3Npfo .index__Title___3U5e6 {\n  font-size: 16px;\n  font-weight: bold;\n  color: #A30B35;\n}\n.index__Details___3AX-7 .index__DroppedProducts___3Npfo.index__noMargin___2RAs6 {\n  margin-top: 0;\n}\n.index__Details___3AX-7 .index__ExtraProducts___2EXMw {\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n}\n.index__Details___3AX-7 .index__ExtraProducts___2EXMw > * {\n  margin-bottom: 10px;\n}\n.index__Details___3AX-7 .index__ExtraProducts___2EXMw > *:last-child {\n  margin-bottom: 0;\n}\n.index__Details___3AX-7 .index__ExtraProducts___2EXMw .index__Title___3U5e6 {\n  font-size: 16px;\n  font-weight: bold;\n  color: #00538D;\n}\n.index__Details___3AX-7 .index__ExtraProducts___2EXMw.index__noMargin___2RAs6 {\n  margin-top: 0;\n}\n@media only screen and (max-width: 955px) {\n  .index__Details___3AX-7 {\n    padding: 52px 24px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Details": "index__Details___3AX-7",
	"BasicInfo": "index__BasicInfo___2LnYf",
	"Description": "index__Description___1b1VL",
	"DroppedProducts": "index__DroppedProducts___3Npfo",
	"Title": "index__Title___3U5e6",
	"noMargin": "index__noMargin___2RAs6",
	"ExtraProducts": "index__ExtraProducts___2EXMw"
};
module.exports = exports;
