//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PopoverBase extends React.Component {
  render() {
    const { className, onClose, children } = this.props;
    const componentClasses = classNames(styles.PopoverBase);

    return (
      <div className={componentClasses}>
        <div className={styles.BackgroundOverlay} onClick={onClose}></div>
        <div className={classNames(styles.Content, { [className]: className })}>
          {onClose && (
            <div className={styles.CloseIcon} onClick={onClose}>
              Close
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default PopoverBase;
