// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-moz-keyframes index__spin___OesyO {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes index__spin___OesyO {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes index__spin___OesyO {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n.index__Auth___3V3fk {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  background-color: #fafafb;\n  height: 100%;\n  overflow: scroll;\n}\n.index__Auth___3V3fk .index__Content___2VNsX {\n  margin-top: 100px;\n}\n.index__Auth___3V3fk .index__Content___2VNsX .index__Form___2ZPfT .index__Spinner___2XwTM {\n  animation: index__spin___OesyO 1500ms linear infinite;\n}\n.index__Auth___3V3fk .index__Content___2VNsX .index__Form___2ZPfT .index__GoBack___l1vhU {\n  font-weight: bold;\n  font-size: 14px;\n  color: #A30B35;\n  margin-top: 12px;\n}\n.index__Auth___3V3fk .index__Content___2VNsX .index__Form___2ZPfT .index__Links___1Mopd {\n  font-weight: bold;\n  font-style: italic;\n  text-decoration: underline;\n  font-size: 16px;\n  color: #08a59d;\n  margin-top: 12px;\n}", ""]);
// Exports
exports.locals = {
	"Auth": "index__Auth___3V3fk",
	"Content": "index__Content___2VNsX",
	"Form": "index__Form___2ZPfT",
	"Spinner": "index__Spinner___2XwTM",
	"spin": "index__spin___OesyO",
	"GoBack": "index__GoBack___l1vhU",
	"Links": "index__Links___1Mopd"
};
module.exports = exports;
