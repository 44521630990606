// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__link___3xgUq {\n  color: #e23023;\n}", ""]);
// Exports
exports.locals = {
	"link": "index__link___3xgUq"
};
module.exports = exports;
