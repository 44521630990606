// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-moz-keyframes index__spin___36SSx {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes index__spin___36SSx {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes index__spin___36SSx {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n.index__StateIndicator___S_7I6 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  align-self: center;\n  color: #54565a;\n  margin: 60px 0;\n  width: 100%;\n}\n.index__StateIndicator___S_7I6 .index__LoadingIndicator___1yhPs {\n  animation: index__spin___36SSx 1500ms linear infinite;\n}\n.index__StateIndicator___S_7I6 .index__ErrorContainer___2HsJa {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n.index__StateIndicator___S_7I6 .index__ErrorContainer___2HsJa > * {\n  margin-bottom: 8px;\n}\n.index__StateIndicator___S_7I6 .index__ErrorContainer___2HsJa > *:last-child {\n  margin-bottom: 0;\n}", ""]);
// Exports
exports.locals = {
	"StateIndicator": "index__StateIndicator___S_7I6",
	"LoadingIndicator": "index__LoadingIndicator___1yhPs",
	"spin": "index__spin___36SSx",
	"ErrorContainer": "index__ErrorContainer___2HsJa"
};
module.exports = exports;
