//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Form, Header, Button, ButtonStyle, Checkbox } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { ScreenSet, showScreenSet } from '@helpers/gigya';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { DoNotSellMyPersonalDataURL } from '@helpers/constants/colgate';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
// API ----------------------------------------------------------------------------
import { verifyClinicCode } from '@api/endpoints/get/providers';
// React Class -----------------------------------------------------------------
class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      gigyaFailure : false
    }
  }

  beforeValidation(e) {
    const ASP_CODE_LENGTH = 6;
    const aspCode = e.formData['data.aspCode'];
    const errorObject = {
      'data.aspCode':
        'The provided ASP code is not in our records.',
      form: 'The form is not valid',
    };
    if (!(/^\d+$/.test(aspCode) && aspCode.length === ASP_CODE_LENGTH))
      return errorObject;
    return new Promise((resolve, reject) => {
      verifyClinicCode(aspCode)
        .then((prpResponse) => {
          if (prpResponse.status === 200) return resolve();

          throw new Error('unexpected PRP Server Response');
        })
        // Based on Gigya documentation, they don't use reject, they use a non-empty argumented resolve.
        .catch(() => resolve(errorObject));
    });
  }

  componentDidMount() {
    showScreenSet(ScreenSet.SignUp, 'signup-container', {
      onBeforeValidation: this.beforeValidation,
    });
  }

  additionalComponent() {
    const { t } = this.props;
    return (
      <div className={styles.AdditionalComponent}>
        <a
          rel="noopener noreferrer"
          className={classNames(styles.Link, styles.SecondaryLink)}
          href={DoNotSellMyPersonalDataURL}
          target="_blank"
        >
          {t('login_DoNotSellPersonalData')}
        </a>
        <Link
          target="_blank"
          className={classNames(styles.Link, styles.SecondaryLink)}
          to="/help"
        >
          {t('login_Help')}
        </Link>
      </div>
    );
  }

  verifyIfGigyaHasLoaded() {
    const gigyaDiv = document.getElementById('signup-container');
    if (gigyaDiv.childElementCount === 0)
      this.setState({gigyaFailure:true})
  }

  render() {
    setTimeout(() => this.verifyIfGigyaHasLoaded(), 2000);
    const { history } = this.props;
    const { gigyaFailure } = this.state;
    const componentClasses = classNames(styles.SignUp);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('login_CreateAnAccount')}</title>
        </Helmet>

        <Header />
        {gigyaFailure ? (
            <span className={styles.GigyaFailure}>{t('login_GigyaFailure')}</span>
          ) :
          <Form
            className={styles.Form}
            title={t('login_CreateAccount')}
            additionalComponent={this.additionalComponent()}
            id="signup-container"
          />
        }
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(SignUp);
