// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-moz-keyframes index__spin___43hHa {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes index__spin___43hHa {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes index__spin___43hHa {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n.index__Payment___2TweM {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  background-color: #fafafb;\n  height: 100%;\n  overflow: scroll;\n}\n.index__Payment___2TweM .index__Content___SaWvb {\n  margin-top: 100px;\n}\n.index__Payment___2TweM .index__Content___SaWvb .index__Form___3-wTk .index__Spinner___1Blx2 {\n  animation: index__spin___43hHa 1500ms linear infinite;\n}\n.index__Payment___2TweM .index__Content___SaWvb .index__Form___3-wTk .index__GoBack___1TBsD {\n  font-weight: bold;\n  font-size: 14px;\n  color: #A30B35;\n  margin-top: 12px;\n}", ""]);
// Exports
exports.locals = {
	"Payment": "index__Payment___2TweM",
	"Content": "index__Content___SaWvb",
	"Form": "index__Form___3-wTk",
	"Spinner": "index__Spinner___1Blx2",
	"spin": "index__spin___43hHa",
	"GoBack": "index__GoBack___1TBsD"
};
module.exports = exports;
