// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__VerifyEmail___2GIE0 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #fafafb;\n  height: 100%;\n  overflow: scroll;\n}", ""]);
// Exports
exports.locals = {
	"VerifyEmail": "index__VerifyEmail___2GIE0"
};
module.exports = exports;
