// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__PopoverBase___k8MPm {\n  position: fixed;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 10;\n}\n.index__PopoverBase___k8MPm .index__BackgroundOverlay___3xXZg {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(17, 17, 17, 0.3);\n  z-index: 0;\n}\n.index__PopoverBase___k8MPm .index__Content___1hrL6 {\n  position: absolute;\n  bottom: 0;\n  margin-bottom: 5px;\n  left: 5px;\n  right: 5px;\n}\n.index__PopoverBase___k8MPm .index__Content___1hrL6 .index__CloseIcon___3wq3H {\n  position: absolute;\n  top: 20px;\n  right: 24px;\n  cursor: pointer;\n  font-size: 18px;\n}", ""]);
// Exports
exports.locals = {
	"PopoverBase": "index__PopoverBase___k8MPm",
	"BackgroundOverlay": "index__BackgroundOverlay___3xXZg",
	"Content": "index__Content___1hrL6",
	"CloseIcon": "index__CloseIcon___3wq3H"
};
module.exports = exports;
