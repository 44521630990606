//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uncontrolledValue: '',
      focus: false,
    };
  }

  render() {
    const {
      className,
      type,
      title,
      placeholder,
      value,
      onChange,
      autocomplete,
      id,
      readonly,
      shouldAutoFocus
    } = this.props;

    const { uncontrolledValue, focus } = this.state;

    const componentClasses = classNames(styles.Input, {
      [className]: className,
      [styles.Focus]: focus,
    });

    return (
      <div className={componentClasses}>
        <span className={styles.Title}>{title}</span>
        <div className={styles.InputField}>
          <input
            type={type || 'text'}
            value={value || uncontrolledValue}
            placeholder={placeholder}
            onChange={({ currentTarget: { value } }) => {
              if (onChange) return onChange(value);
              this.setState({ uncontrolledValue: value });
            }}
            className={styles.Input}
            autoComplete={autocomplete}
            id={id}
            readOnly={readonly}
            onFocus={() => this.setState({ focus: true })}
            onBlur={() => this.setState({ focus: false })}
            autoFocus={shouldAutoFocus}
          />
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Input;
