// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__GenericPopover___3Qlnf {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  background-color: white;\n  padding: 30px;\n  z-index: 17;\n}\n.index__GenericPopover___3Qlnf > * {\n  margin-bottom: 15px;\n}\n.index__GenericPopover___3Qlnf > *:last-child {\n  margin-bottom: 0;\n}\n.index__GenericPopover___3Qlnf .index__Title___3BBTY {\n  margin-top: 2px;\n  font-size: 18px;\n  flex-grow: 2;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n.index__GenericPopover___3Qlnf .index__Subtitle___USzbq {\n  font-size: 16px;\n  line-height: 22px;\n  font-weight: normal;\n}\n.index__GenericPopover___3Qlnf .index__Subtitle___USzbq a {\n  color: inherit;\n  text-decoration: underline;\n}\n.index__GenericPopover___3Qlnf .index__Auxiliar___JDfHS {\n  background-color: #54565a;\n  line-height: 22px;\n  padding: 15px;\n  color: #54565a;\n}", ""]);
// Exports
exports.locals = {
	"GenericPopover": "index__GenericPopover___3Qlnf",
	"Title": "index__Title___3BBTY",
	"Subtitle": "index__Subtitle___USzbq",
	"Auxiliar": "index__Auxiliar___JDfHS"
};
module.exports = exports;
