// to-do: join all these metafield constants into one w/ formatted objects
// this is ugly

export const MetafieldsReadableKeys = {
  details: 'Details',
  characteristics: 'Characteristics',
  benefits: 'Benefits',
  indications_and_usage: 'Indications and Usage',
  information: 'Information',
  safety_info: 'Important Safety Information',
  faq: 'Product FAQ',
  educational_materials: 'Educational Materials',
};

export const MetafieldsKeys = {
  DETAILS: 'details',
  CHARACTERISTICS: 'characteristics',
  BENEFITS: 'benefits',
  INDICATIONS_AND_USAGE: 'indications_and_usage',
  INFORMATION: 'information',
  SAFETY_INFO: 'safety_info',
  FAQ: 'faq',
  DISCOUNTED_PRICE: 'discounted_price',
  FULL_PRICE: 'full_price',
  VIDEOS: 'videos',
  EDUCATIONAL_MATERIALS: 'educational_materials',
};

export const MetafieldOrder = [
  MetafieldsKeys.DETAILS,
  MetafieldsKeys.CHARACTERISTICS,
  MetafieldsKeys.BENEFITS,
  MetafieldsKeys.INDICATIONS_AND_USAGE,
  MetafieldsKeys.INFORMATION,
  MetafieldsKeys.SAFETY_INFO,
  MetafieldsKeys.FAQ,
  MetafieldsKeys.EDUCATIONAL_MATERIALS,
];

export const ProductsSortingOptions = [
  { value: 'published_at desc', label: 'Newest Added', default: true },
  { value: 'price asc', label: 'Price Low to High' },
  { value: 'price desc', label: 'Price High to Low' },
];

export const EltaProductCategories = [
  {
    title: 'All Sun Care',
    tags: new Set([
      'Sun Care',
    ])
  },
  {
    title: 'Face Sunscreen',
    tags: new Set([
      'Face Sunscreen'
    ])
  },
  {
    title: 'Tinted Sunscreen',
    tags: new Set([
      'Tinted Sunscreen'
    ])
  },
  {
    title: 'Full Body Sunscreen',
    tags: new Set([
      'Body Sunscreen'
    ])
  },
  {
    title: 'All Skin Care',
    tags: new Set([
      'Skin Care'
    ])
  },
  {
    title: 'Post Procedure',
    tags: new Set([
      'Post-procedure'
    ])
  }
]

/**
 * Function that filters products.
 * A product will not be included in the list
 * under these conditions:
 *   - Status is different from active.
 *   - Includes NO_EPC tag
 *   - If product has not the desired matching tag e.g. Skin Care.
 *
 * @param products
 * @param idx
 */
export const productsFilterByShopifyCategory = (products, idx) => {
  const filtered = products.filter(( product ) => {
    const { status, tags, product_type} = product
    if (status !== 'active') return false;
    let traits = tags.split(',').map((tag)=>tag.trim())
    traits.push(product_type.trim())
    traits = new Set(traits)
    if (traits.has('NO_EPC')) return false;
    if(idx === null) return true
    const intersection = new Set([...traits].filter(x =>
      EltaProductCategories[idx].tags.has(x)));
    if (intersection?.size > 0) return true
    return false
  })
  return filtered
}
