//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import qs from 'query-string';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { auth } from '@helpers/auth';
import { gigyaFormatter } from '@helpers/formatter';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import {
  GigyaStatus,
  GigyaErrorStatus,
  GigyaSessionDuration,
} from '@helpers/constants/gigya';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { signIn } from '@api/endpoints/post/auth';
//------------------------------------------------------------------------------
// Screen Sets -----------------------------------------------------------------
export const ScreenSet = {
  SignIn: {
    screenSet: 'Elta-RegistrationLogin',
    startScreen: 'gigya-login-screen',
    shouldAuthenticate: true,
    events: {
      onAfterScreenLoad: (event) => removeEmptyAddresses(event),
    },
  },
  SignUp: {
    screenSet: 'Elta-RegistrationLogin',
    startScreen: 'gigya-register-screen',
    shouldAuthenticate: true,
    events: {
      onAfterScreenLoad: (event) => removeEmptyAddresses(event),
    },
  },
  VerifyEmail: {
    screenSet: 'Elta-RegistrationLogin',
    startScreen: 'gigya-verification-pending-screen',
  },
};

export const showScreenSet = (
  { screenSet, startScreen, shouldAuthenticate, events },
  containerID,
  params,
  callback
) => {
  gigya.accounts.showScreenSet({
    screenSet,
    startScreen,
    containerID,
    sessionExpiration: GigyaSessionDuration,
    ...params,
    ...events,
    onAfterSubmit: ({ response }) => {
      if (shouldAuthenticate) {
        if (response.status === GigyaStatus.SUCCESS) {
          const formattedResponse = gigyaFormatter.response(response);
          auth(formattedResponse);
        }
      }
    },
  });
};
//------------------------------------------------------------------------------
// Authentication --------------------------------------------------------------

export const verifyLogin = (authUser) => {
  return new Promise((resolve, reject) => {
    const { UID } = authUser;
    if (!UID) return reject();

    gigya.accounts.verifyLogin({
      UID,
      include: 'identities-all,emails,data',
      callback: resolve,
    });
  });
};

//------------------------------------------------------------------------------
// Registration ----------------------------------------------------------------
const removeEmptyAddresses = () => {
  const templates = document.querySelectorAll(
    ".gigya-array-manager[name='data.addresses'] .gigya-array-template"
  );
  if (!templates || templates.length <= 1) return;

  for (let i = 1; i < templates.length; i++) {
    let template = templates[i];

    // In the template, find the element with class .input-wrapper
    let wrapper = template.querySelector('.inputs-wrapper');
    if (!wrapper) continue;

    let shouldClear = true;

    // In the input-wrapper, find all inputs
    let inputs = wrapper.querySelectorAll('input');

    for (let j = 0; j < inputs.length; j++) {
      let input = inputs[j];

      if (input.value && input.value.length > 0) {
        shouldClear = false;
        break;
      }
    }

    // In the input-wrapper, find all selects
    let selects = wrapper.querySelectorAll('select');

    for (let k = 0; k < selects.length; k++) {
      let select = selects[k];

      if (select.value && select.value.length > 0) {
        shouldClear = false;
        break;
      }
    }

    if (shouldClear) {
      let removeButton = template.querySelector(
        'button.gigya-array-remove-btn'
      );

      if (removeButton) removeButton.click();
    }
  }
};
