//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import qs from 'query-string';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Form, Header, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { GigyaStatus, GigyaSessionDuration } from '@helpers/constants/gigya';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitted: false,
      token: null,
      password: '',
      passwordConfirmation: '',
      errorMessage: null,
    };
  }

  componentDidMount() {
    let that = this;
    const { location, history } = this.props;
    const { pwrt, apiKey } = qs.parse(location.search);

    if (!apiKey || apiKey.length <= 0 || !pwrt || pwrt.length <= 0) {
      history.replace('/');
      return;
    }

    gigya.accounts.showScreenSet({
      screenSet: 'Elta-RegistrationLogin',
      containerID: 'reset-password-container',
      startScreen: 'gigya-reset-password-screen',
      sessionExpiration: GigyaSessionDuration,
      onAfterSubmit: ({ response }) => {
        that.setState({ submitted: response.status !== GigyaStatus.FAIL });
      },
    });
  }

  resetForm() {
    const { t } = this.props;
    const {
      password,
      passwordConfirmation,
      errorMessage,
      loading,
    } = this.state;

    return (
      <Form
        className={styles.Form}
        title={t('login_SetNewPassword')}
        id="reset-password-container"
      />
    );
  }

  submittedForm() {
    const { t } = this.props;
    return (
      <Form
        className={styles.Form}
        title={t('login_ResetYourPasswordSuccess')}
        subtitle={t('login_ResetYourPasswordSuccessSubtitle')}
        submit={t('login_BackToLogin')}
        submitTo="/login"
      />
    );
  }

  render() {
    const { className, t } = this.props;
    const { submitted } = this.state;

    const componentClasses = classNames(styles.ResetPassword, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('login_ResetYourPassword')}</title>
        </Helmet>

        <Header />
        {submitted ? this.submittedForm() : this.resetForm()}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(ResetPassword);
