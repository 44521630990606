// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Landing___1pSkr {\n  margin-top: 70px;\n}\n.index__Landing___1pSkr .index__Content___164aq {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n@media only screen and (max-width: 955px) {\n  .index__Landing___1pSkr {\n    margin-top: 62px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Landing": "index__Landing___1pSkr",
	"Content": "index__Content___164aq"
};
module.exports = exports;
