//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const ModalState = {
  Visible: true,
  Hidden: false,
};
//------------------------------------------------------------------------------
// Functions -------------------------------------------------------------------
function withModal(WrappedComponent) {
  class WithModal extends React.Component {
    constructor(props) {
      super(props);
      this.previousLocation = this.props.location;
    }

    componentDidUpdate(prevProps) {
      const { location, fullScreen } = this.props;
      const { location: prevLocation } = prevProps;

      const isModal = location.state && location.state.modal;
      const isPrevModal = prevLocation.state && prevLocation.state.modal;

      if (location !== prevLocation && !isModal && !isPrevModal) {
        window.scrollTo(0, 0);
        document.body.scrollTo(0, 0);
      }

      if (!prevLocation.state || !prevLocation.state.modal) {
        this.previousLocation = prevLocation;
      }

      if (!this.component || !this.component.didUpdateModalState) return;

      if (isModal && !isPrevModal) {
        this.component.didUpdateModalState(ModalState.Visible);
      } else if (!isModal) {
        this.component.didUpdateModalState(ModalState.Hidden);
      }
    }

    render() {
      const { location } = this.props;

      const isModal =
        location.state &&
        location.state.modal &&
        this.previousLocation !== location;

      const from =
        (isModal && location.state && location.state.from) || location;

      return (
        <WrappedComponent
          isModal={isModal}
          from={from}
          {...this.props}
          ref={(ref) => (this.component = ref)}
        />
      );
    }
  }

  WithModal.displayName = `WithModal(${getDisplayName(WrappedComponent)})`;

  return WithModal;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export { withModal, ModalState };
