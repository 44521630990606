import client from '@api/client';

export const createLoginLink = () => {
  return client.post('/providers/create-login-link', {
    redirectUrl: window.location.href,
  });
};

export const updateRewardSelection = (rewardSelection) => {
  return client.post('/providers/update-reward-selection', {
    rewardSelection: rewardSelection,
  });
};

export const sendClinicCode = (aspCode) => {
  return client.post(`/providers/me/clinic-code`, {
    clinicCode: aspCode
  });
};
