// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__CreateAccount___1jx0X {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: white;\n  background-color: #A30B35;\n  padding: 85px 0 70px 0;\n}\n.index__CreateAccount___1jx0X .index__Title___1t9WQ {\n  font-weight: bold;\n  font-size: 40px;\n  margin-bottom: 8px;\n}\n@media only screen and (max-width: 576px) {\n  .index__CreateAccount___1jx0X .index__Title___1t9WQ {\n    font-size: 24px;\n  }\n}\n.index__CreateAccount___1jx0X .index__Subtitle___1DqW0 {\n  font-weight: normal;\n  font-size: 16px;\n  margin-bottom: 40px;\n}\n.index__CreateAccount___1jx0X .index__Button___3SD65 {\n  font-size: 16px;\n  background-color: #FFFFFF;\n  color: black;\n}\n@media only screen and (max-width: 955px) {\n  .index__CreateAccount___1jx0X {\n    padding: 40px 40px 80px 40px;\n    text-align: center;\n  }\n}", ""]);
// Exports
exports.locals = {
	"CreateAccount": "index__CreateAccount___1jx0X",
	"Title": "index__Title___1t9WQ",
	"Subtitle": "index__Subtitle___1DqW0",
	"Button": "index__Button___3SD65"
};
module.exports = exports;
