//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button, ButtonStyle, AddItem as AddItemComponent } from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { logoPlaceholderUrl } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { shopifyFormatter } from '@helpers/formatter';
import { CurrencyPrefix } from '@helpers/constants/currency';
import { withTranslation } from 'react-i18next';
import { trackProductsViewed, trackProductClicked } from '@helpers/tracking';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = { flashingItems: [] };
    this.itemComponent = this.itemComponent.bind(this);
  }


  itemComponent(item) {
    let that = this;
    const { flashingItems } = this.state;

    const { onAdd, i18n } = this.props;
    const price = shopifyFormatter.product(item).fullPrice(CurrencyPrefix.USD) || '$0.00';

    const componentClasses = classNames(styles.Item, {
      [styles.Flashing]: flashingItems.indexOf(item.id) > -1,
    });

    return (
      <div className={componentClasses} key={item.id}>
        <Link
          onClick={() => trackProductClicked(item, 'dashboard', 'dashboard')}
          className={styles.Image}
          to={`/product/${item.id}`}
          style={{
            backgroundImage: `url(${
              (item.image && item.image.src) || logoPlaceholderUrl(i18n)
            })`,
          }}
        >{item.title}</Link>
        <div className={styles.Info}>
          <Link
            onClick={() => trackProductClicked(item, 'dashboard', 'dashboard')}
            to={`/product/${item.id}`}
            className={styles.Name}
            tabIndex={-1}>
            {item.title}
          </Link>
          <div className={styles.Pricing}>
            <span className={styles.Amount}>{price}</span>
          </div>
        </div>
        <AddItemComponent
          product={item}
          className={styles.AddItem}
          disableTabIndex={false}/>
      </div>
    );
  }

  render() {
    const { products, className, t } = this.props;
    if ( products && products.length > 0)
      trackProductsViewed(products, 'dashboard', 'dashboard');
    
    if (!products?.length)
      return <p className={styles.NoResultsText}>{t('dashboard_NoResultsFound')}</p>;

    const componentClasses = classNames(styles.List, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>{products.map(this.itemComponent)}</div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(List);
