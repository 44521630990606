// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__SignIn___3gWHy {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  background-color: #fafafb;\n  height: 100%;\n  min-height: 100vh;\n  padding-top: 100px;\n  overflow: scroll;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Link___3dU2f {\n  font-weight: bold;\n  font-size: 14px;\n  color: #A30B35;\n  margin-top: 12px;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Link___3dU2f.index__Create___FyLuB {\n  margin-top: 24px;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Footer___3WIm1 {\n  display: flex;\n  flex-direction: column;\n  margin-top: 46px;\n  font-weight: bold;\n  font-size: 14px;\n  color: #54565a;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Footer___3WIm1 > * {\n  margin-bottom: 10px;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Footer___3WIm1 > *:last-child {\n  margin-bottom: 0;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Footer___3WIm1 .index__Row___8ALnV {\n  display: flex;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Footer___3WIm1 .index__Row___8ALnV > * {\n  margin-right: 4px;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Footer___3WIm1 .index__Row___8ALnV > *:last-child {\n  margin-right: 0;\n}\n.index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI .index__Footer___3WIm1 a {\n  color: inherit;\n}\n.index__SignIn___3gWHy .index__GigyaFailure___3_BCc {\n  font-size: 20px;\n  color: #A30B35;\n  font-weight: bold;\n  margin-top: 100px;\n}\n@media only screen and (max-width: 955px) {\n  .index__SignIn___3gWHy .index__Content___Ylvi_ {\n    width: 90%;\n  }\n  .index__SignIn___3gWHy .index__Content___Ylvi_ .index__Form___3TOoI {\n    width: 100%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"SignIn": "index__SignIn___3gWHy",
	"Content": "index__Content___Ylvi_",
	"Form": "index__Form___3TOoI",
	"Link": "index__Link___3dU2f",
	"Create": "index__Create___FyLuB",
	"Footer": "index__Footer___3WIm1",
	"Row": "index__Row___8ALnV",
	"GigyaFailure": "index__GigyaFailure___3_BCc"
};
module.exports = exports;
