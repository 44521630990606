// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Checkbox___1aP_A {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.index__Checkbox___1aP_A .index__Text___3uygQ {\n  font-weight: bold;\n  font-size: 12px;\n}\n.index__Checkbox___1aP_A .index__Text___3uygQ a {\n  color: #A30B35;\n  text-decoration: underline;\n}\n.index__Checkbox___1aP_A .index__CheckboxInput___TUFl4 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 16px;\n  max-width: 16px;\n  min-height: 16px;\n  max-height: 16px;\n  background: #ffffff;\n  border: 2px solid rgba(214, 214, 214, 0.5);\n  margin-right: 9px;\n}\n.index__Checkbox___1aP_A.index__Checked___10dKi .index__CheckboxInput___TUFl4 {\n  border: none;\n  background-color: #A30B35;\n}", ""]);
// Exports
exports.locals = {
	"Checkbox": "index__Checkbox___1aP_A",
	"Text": "index__Text___3uygQ",
	"CheckboxInput": "index__CheckboxInput___TUFl4",
	"Checked": "index__Checked___10dKi"
};
module.exports = exports;
