//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button } from '@cmp/common';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CreateAccount extends React.Component {
  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.CreateAccount);

    return (
      <div className={componentClasses}>
        <h2 className={styles.Title}>{t('landing_CreateAccountToday')}</h2>
        <span className={styles.Subtitle}>
          {t('landing_YoullRecommendProducts')}
        </span>
        <Button to="/signup" className={styles.Button}>
          {t('landing_GetStarted')}
        </Button>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(CreateAccount);
