// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__AddItem___3DcGV {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n.index__AddItem___3DcGV .index__Button___2ZUfW {\n  background: #A30B35;\n  color: white;\n  align-self: flex-start;\n  font: italic normal 700 14px \"Open Sans\";\n  padding: 7px 16px;\n  min-height: 38px;\n}\n.index__AddItem___3DcGV.index__Disabled___1Gd6E {\n  cursor: default;\n  opacity: 0.8;\n  background: #d6d6d6 0% 0% no-repeat padding-box !important;\n}\n.index__AddItem___3DcGV .index__ErrorMessage___RBEJp {\n  margin-top: 8px;\n}\n.index__AddItem___3DcGV.index__Added___3M24J .index__Button___2ZUfW {\n  text-align: left;\n  font: italic normal 700 14px \"Open Sans\";\n  letter-spacing: 0px;\n  color: #707372;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  opacity: 1;\n  pointer-events: none;\n}\n@media only screen and (max-width: 955px) {\n  .index__AddItem___3DcGV .index__Button___2ZUfW {\n    width: 100%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"AddItem": "index__AddItem___3DcGV",
	"Button": "index__Button___2ZUfW",
	"Disabled": "index__Disabled___1Gd6E",
	"ErrorMessage": "index__ErrorMessage___RBEJp",
	"Added": "index__Added___3M24J"
};
module.exports = exports;
