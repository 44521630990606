// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Docs___1BQgh {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  min-height: 100%;\n  background: rgba(17, 17, 17, 0.95);\n  padding: 68px 0 0 0;\n}\n.index__Docs___1BQgh .index__Content___2SYMB {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  background-color: white;\n  min-width: 475px;\n  max-width: 475px;\n  padding: 52px 46px 26px 46px;\n}\n.index__Docs___1BQgh .index__Content___2SYMB > * {\n  margin-bottom: 19px;\n}\n.index__Docs___1BQgh .index__Content___2SYMB > *:last-child {\n  margin-bottom: 0;\n}\n.index__Docs___1BQgh .index__Content___2SYMB .index__Title___1Mgcf {\n  align-self: center;\n  font-size: 24px;\n  font-weight: bold;\n}\n.index__Docs___1BQgh .index__Content___2SYMB .index__Text___3Je-p {\n  font-size: 14px;\n}\n.index__Docs___1BQgh .index__Content___2SYMB .index__Text___3Je-p > p {\n  margin: 10px 0;\n}\n.index__Docs___1BQgh .index__Content___2SYMB .index__Close___2kyko {\n  position: absolute;\n  right: 24px;\n  top: 24px;\n  cursor: pointer;\n}\n@media only screen and (max-width: 955px) {\n  .index__Docs___1BQgh {\n    padding: 38px 0 0 0;\n  }\n  .index__Docs___1BQgh .index__Content___2SYMB {\n    width: 100%;\n    min-width: 0;\n    max-width: none;\n    padding: 26px;\n  }\n  .index__Docs___1BQgh .index__Content___2SYMB > * {\n    margin-bottom: 0;\n  }\n  .index__Docs___1BQgh .index__Content___2SYMB > *:last-child {\n    margin-bottom: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Docs": "index__Docs___1BQgh",
	"Content": "index__Content___2SYMB",
	"Title": "index__Title___1Mgcf",
	"Text": "index__Text___3Je-p",
	"Close": "index__Close___2kyko"
};
module.exports = exports;
