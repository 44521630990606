// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Patient___3x0nX {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 60px;\n  max-width: 475px;\n}\n.index__Patient___3x0nX > * {\n  margin-bottom: 32px;\n}\n.index__Patient___3x0nX > *:last-child {\n  margin-bottom: 0;\n}\n.index__Patient___3x0nX .index__Header___2VufW {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n.index__Patient___3x0nX .index__Header___2VufW > * {\n  margin-bottom: 11px;\n}\n.index__Patient___3x0nX .index__Header___2VufW > *:last-child {\n  margin-bottom: 0;\n}\n.index__Patient___3x0nX .index__Header___2VufW .index__Title___2xPeu {\n  font-weight: bold;\n  font-size: 24px;\n}\n.index__Patient___3x0nX .index__Header___2VufW .index__Subtitle___1KUCy {\n  font-size: 14px;\n  color: #000000;\n}\n.index__Patient___3x0nX .index__Form___3svPW {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.index__Patient___3x0nX .index__Form___3svPW > * {\n  margin-bottom: 16px;\n}\n.index__Patient___3x0nX .index__Form___3svPW > *:last-child {\n  margin-bottom: 0;\n}\n.index__Patient___3x0nX .index__Form___3svPW .index__Auxiliar___2312l {\n  font-size: 14px;\n  color: #54565a;\n  align-self: center;\n}\n.index__Patient___3x0nX .index__Form___3svPW .index__Input___2OpWy,\n.index__Patient___3x0nX .index__Form___3svPW .index__Button___3VvyM {\n  width: 100%;\n  max-width: 280px;\n}\n.index__Patient___3x0nX .index__Form___3svPW .index__Disclosure___2QR8a {\n  font-size: 12px;\n  color: #54565a;\n  text-align: center;\n}\n@media only screen and (max-width: 955px) {\n  .index__Patient___3x0nX {\n    padding: 40px 30px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Patient": "index__Patient___3x0nX",
	"Header": "index__Header___2VufW",
	"Title": "index__Title___2xPeu",
	"Subtitle": "index__Subtitle___1KUCy",
	"Form": "index__Form___3svPW",
	"Auxiliar": "index__Auxiliar___2312l",
	"Input": "index__Input___2OpWy",
	"Button": "index__Button___3VvyM",
	"Disclosure": "index__Disclosure___2QR8a"
};
module.exports = exports;
