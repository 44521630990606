// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__ForgotPassword___cLY3e {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  background-color: #fafafb;\n  height: 100%;\n}\n.index__ForgotPassword___cLY3e .index__Form___10wDR {\n  margin: 100px 0 20px 0;\n}\n.index__ForgotPassword___cLY3e .index__Form___10wDR .index__GoBack___1F1MG {\n  font-weight: bold;\n  font-size: 14px;\n  color: #A30B35;\n  margin-top: 12px;\n}\n@media only screen and (max-width: 955px) {\n  .index__ForgotPassword___cLY3e .index__Form___10wDR {\n    width: 90%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"ForgotPassword": "index__ForgotPassword___cLY3e",
	"Form": "index__Form___10wDR",
	"GoBack": "index__GoBack___1F1MG"
};
module.exports = exports;
