// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Dashboard___35QB8 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  min-height: 100%;\n}\n.index__Dashboard___35QB8 .index__Content___DC3EW {\n  display: flex;\n  align-items: stretch;\n  padding-top: 120px;\n  flex-grow: 2;\n  background-color: #fafafb;\n  width: 100%;\n  min-height: 100%;\n}\n.index__Dashboard___35QB8 .index__Content___DC3EW.index__fullScreen___2gvol {\n  padding-top: 0;\n}\n.index__Dashboard___35QB8 .index__Content___DC3EW .index__Links___1412Y {\n  font-weight: bold;\n  text-decoration: underline;\n  font-size: 16px;\n  color: #08a59d;\n  margin-top: 12px;\n}\n@media only screen and (max-width: 955px) {\n  .index__Dashboard___35QB8 .index__Content___DC3EW {\n    padding-top: 74px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Dashboard": "index__Dashboard___35QB8",
	"Content": "index__Content___DC3EW",
	"fullScreen": "index__fullScreen___2gvol",
	"Links": "index__Links___1412Y"
};
module.exports = exports;
