// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__SignUp___sNZ7t {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  background-color: #fafafb;\n  height: 100%;\n  padding-top: 100px;\n  overflow: scroll;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO {\n  max-width: 716px;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__Row___1-WWd {\n  display: flex;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__Row___1-WWd > * {\n  margin-right: 8px;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__Row___1-WWd > *:last-child {\n  margin-right: 0;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__Password___23PSs {\n  display: flex;\n  flex-direction: column;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__Password___23PSs .index__Subtitle___140H0 {\n  align-self: flex-start;\n  margin-top: 10px;\n  font-size: 12px;\n  color: #54565a;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__AdditionalComponent___11BWC {\n  display: flex;\n  flex-direction: column;\n  margin-top: 25px;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__AdditionalComponent___11BWC .index__Link___1SKw5 {\n  font-weight: bold;\n  font-size: 14px;\n  color: #A30B35;\n  margin-bottom: 39px;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__AdditionalComponent___11BWC .index__Link___1SKw5.index__SecondaryLink___1qx9e {\n  color: #54565a;\n  margin-bottom: 10px;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__AdditionalComponent___11BWC .index__Link___1SKw5:last-child {\n  margin-bottom: 0;\n}\n.index__SignUp___sNZ7t .index__Form___3sgiO .index__AdditionalComponent___11BWC .index__Text___1x2s5 {\n  font-size: 14px;\n  color: #8e8e8e;\n  font-weight: bold;\n}\n.index__SignUp___sNZ7t .index__GigyaFailure___3lyr0 {\n  font-size: 20px;\n  color: #A30B35;\n  font-weight: bold;\n  margin-top: 50px;\n}\n@media only screen and (max-width: 955px) {\n  .index__SignUp___sNZ7t .index__Form___3sgiO {\n    width: 90%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"SignUp": "index__SignUp___sNZ7t",
	"Form": "index__Form___3sgiO",
	"Row": "index__Row___1-WWd",
	"Password": "index__Password___23PSs",
	"Subtitle": "index__Subtitle___140H0",
	"AdditionalComponent": "index__AdditionalComponent___11BWC",
	"Link": "index__Link___1SKw5",
	"SecondaryLink": "index__SecondaryLink___1qx9e",
	"Text": "index__Text___1x2s5",
	"GigyaFailure": "index__GigyaFailure___3lyr0"
};
module.exports = exports;
