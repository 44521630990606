// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Error___2JUyd {\n  height: 19px;\n  width: 22px;\n  fill: #54565a;\n}", ""]);
// Exports
exports.locals = {
	"Error": "index__Error___2JUyd"
};
module.exports = exports;
