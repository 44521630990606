// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__HowItWorks___2jhf2 {\n  margin: 0 30px;\n  padding: 50px 0 92px 0;\n}\n.index__HowItWorks___2jhf2 .index__Subcontent___KrNg- > .index__Overhead___1E54w {\n  color: #A30B35;\n  font-size: 16px;\n}\n.index__HowItWorks___2jhf2 .index__Subcontent___KrNg- > .index__Title___2hi0e {\n  color: #000000;\n  font-size: 40px;\n  font-weight: bold;\n}\n.index__HowItWorks___2jhf2 .index__Subcontent___KrNg- > .index__Subtitle___3vJeI {\n  color: #54565a;\n  font-weight: normal;\n  font-size: 16px;\n}\n.index__HowItWorks___2jhf2 .index__Subcontent___KrNg- .index__Button___8k9Or {\n  margin-top: 23px;\n}\n@media only screen and (max-width: 955px) {\n  .index__HowItWorks___2jhf2 .index__Subcontent___KrNg- > .index__Title___2hi0e {\n    font-size: 32px;\n  }\n}\n@media only screen and (max-width: 320px) {\n  .index__HowItWorks___2jhf2 .index__Subcontent___KrNg- > .index__Title___2hi0e {\n    font-size: 24px;\n  }\n}\n.index__HowItWorks___2jhf2 .index__Subcontent___KrNg- {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.index__HowItWorks___2jhf2 .index__Subcontent___KrNg- .index__Title___2hi0e {\n  margin-bottom: 46px;\n}\n@media only screen and (max-width: 955px) {\n  .index__HowItWorks___2jhf2 {\n    padding: 26px 40px;\n  }\n  .index__HowItWorks___2jhf2 .index__Subcontent___KrNg- .index__Title___2hi0e,\n.index__HowItWorks___2jhf2 .index__Subcontent___KrNg- .index__Subtitle___3vJeI {\n    text-align: center;\n  }\n}", ""]);
// Exports
exports.locals = {
	"HowItWorks": "index__HowItWorks___2jhf2",
	"Subcontent": "index__Subcontent___KrNg-",
	"Overhead": "index__Overhead___1E54w",
	"Title": "index__Title___2hi0e",
	"Subtitle": "index__Subtitle___3vJeI",
	"Button": "index__Button___8k9Or"
};
module.exports = exports;
