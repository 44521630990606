//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class FilterSelector extends React.Component {
  render() {
    const {
      className,
      title,
      custom,
      onFocus = () => {},
      ...props
    } = this.props;
    const componentClasses = classNames(styles.FilterSelector, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {custom || (
          <Select
            onFocus={() => onFocus()}
            classNamePrefix="filter-select"
            isSearchable={false}
            formatOptionLabel={({ label }, { context }) => {
              const isValue = context === 'value';

              const optionClasses = classNames(styles.OptionLabel, {
                [styles.Value]: isValue,
              });

              return (
                <p className={optionClasses}>
                  {isValue && (
                    <span className={styles.SelectorTitle}>{title}: </span>
                  )}
                  <span className={styles.Label}>{label}</span>
                </p>
              );
            }}
            {...props}
          />
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default FilterSelector;
