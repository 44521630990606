// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__ModalBase___uoQ6A {\n  position: fixed;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  top: 0;\n  left: 0;\n  padding: 20px;\n  width: 100%;\n  height: 100%;\n  z-index: 10;\n  overflow: scroll;\n}\n.index__ModalBase___uoQ6A .index__BackgroundOverlay___3yD_t {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(17, 17, 17, 0.3);\n  z-index: 0;\n}\n.index__ModalBase___uoQ6A .index__Content___2B5Za {\n  position: relative;\n  background-color: white;\n  z-index: 1;\n}\n.index__ModalBase___uoQ6A .index__Content___2B5Za .index__CloseIcon___HdNJD {\n  position: absolute;\n  top: 20px;\n  right: 24px;\n  cursor: pointer;\n  font-size: 18px;\n}\n@media only screen and (max-width: 955px) {\n  .index__ModalBase___uoQ6A {\n    padding: 40px 0;\n  }\n  .index__ModalBase___uoQ6A .index__BackgroundOverlay___3yD_t {\n    background: rgba(17, 17, 17, 0.95);\n  }\n}", ""]);
// Exports
exports.locals = {
	"ModalBase": "index__ModalBase___uoQ6A",
	"BackgroundOverlay": "index__BackgroundOverlay___3yD_t",
	"Content": "index__Content___2B5Za",
	"CloseIcon": "index__CloseIcon___HdNJD"
};
module.exports = exports;
