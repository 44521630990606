//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Badge, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { referralFormatter } from '@helpers/formatter';
import { ReferralStatus } from '@helpers/constants/referral';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CheckoutDetails extends React.Component {
  valueRow(title, value) {
    return (
      <div className={styles.Row}>
        <span className={styles.Title}>{title}</span>
        <span className={styles.Value}>{value}</span>
      </div>
    );
  }

  render() {
    const { referral, action, onClick, loading, disabled, t } = this.props;
    const finalPrice = referralFormatter.finalPrice(referral);

    const componentClasses = classNames(styles.CheckoutDetails);

    return (
      <div className={componentClasses}>
        {(finalPrice.discountProvided || finalPrice.subtotal) && (
          <div className={styles.Values}>
            {this.valueRow(
              t('dashboard_Subtotal'),
              `$${finalPrice.subtotal || '0'}`
            )}
          </div>
        )}
        {referralFormatter.status(referral) !==
        ReferralStatus.AdditionalPurchase ? (
          <Button
            style={ButtonStyle.Primary}
            className={styles.Button}
            onClick={onClick}
            loading={loading}
            disabled={loading || disabled}
          >
            {action}
          </Button>
        ) : null}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(CheckoutDetails);
