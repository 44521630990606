//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Form, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { ScreenSet, showScreenSet } from '@helpers/gigya';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { DoNotSellMyPersonalDataURL } from '@helpers/constants/colgate';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gigyaFailure: false,
    };
  }

  componentDidMount() {
    showScreenSet(ScreenSet.SignIn, 'login-container');
  }

  additionalComponent() {
    const { t } = this.props;
    return (
      <>
        <Link to="/signup" className={classNames(styles.Link, styles.Create)}>
          {t('login_CreateAccount')}
        </Link>
        <div className={styles.Footer}>
          <div className={styles.Row}>
            <a
              href="https://eltamd.com/pages/eltamd-procart-terms-of-use"
              target="_blank"
            >
              {t('login_TermsOfService')}{' '}
            </a>
            <span>&bull;</span>
            <a
              href="https://eltamd.com/policies/privacy-policy"
              target="_blank"
            >
              {t('login_PrivacyPolicy')}
            </a>
          </div>
          <a
            rel="noopener noreferrer"
            href={DoNotSellMyPersonalDataURL}
            target="_blank"
          >
            {t('login_DoNotSellPersonalData')}
          </a>
          <Link to="/help" target="_blank">
            {t('login_Help')}
          </Link>
        </div>
      </>
    );
  }

  verifyIfGigyaHasLoaded() {
    const gigyaDiv = document.getElementById('login-container');
    if (gigyaDiv.childElementCount === 0) this.setState({ gigyaFailure: true });
  }

  render() {
    setTimeout(() => this.verifyIfGigyaHasLoaded(), 2000);
    const { t } = this.props;
    const { gigyaFailure } = this.state;
    const componentClasses = classNames(styles.SignIn);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title>{t('landing_LogIn')}</title>
        </Helmet>

        <Header />
        <div className={styles.Content}>
          {gigyaFailure ? (
            <span className={styles.GigyaFailure}>
              {t('login_GigyaFailure')}
            </span>
          ) : (
            <Form
              className={styles.Form}
              title={t('login_ProfessionalLogin')}
              onSubmit={() => this.setState({ submitted: true })}
              additionalComponent={this.additionalComponent()}
              id="login-container"
            />
          )}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(SignIn);
