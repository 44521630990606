// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__GenericModal___gUIoJ {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  padding: 52px 75px;\n  max-width: 475px;\n}\n.index__GenericModal___gUIoJ .index__Title___1-KaS {\n  font-weight: bold;\n  font-size: 24px;\n  margin-bottom: 11px;\n}\n.index__GenericModal___gUIoJ .index__Subtitle___8gVWu {\n  font-size: 14px;\n  color: #54565a;\n  margin-bottom: 18px;\n}\n@media only screen and (max-width: 955px) {\n  .index__GenericModal___gUIoJ {\n    padding: 52px 30px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"GenericModal": "index__GenericModal___gUIoJ",
	"Title": "index__Title___1-KaS",
	"Subtitle": "index__Subtitle___8gVWu"
};
module.exports = exports;
