//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FaSpinner } from 'react-icons/fa';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
export const ButtonStyle = {
  Default: 'Default',
  Secondary: 'Secondary',
  Custom: 'Custom',
  ModalButton: 'ModalButton',
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Button extends React.Component {
  constructor(props) {
    super(props);
  }

  eventHandler(e, disabled, loading, onClick, history, to) {
    let handlerFn = () => {}; // noop
    const enterKeyPressedOrClicked = e.type === 'click' || e.key === 'Enter';
    if (!disabled && !loading && enterKeyPressedOrClicked) {
      if (onClick) {
        handlerFn = onClick;
      } else if (to) {
        handlerFn = () => history.push(to);
      }
    }
    handlerFn();
  }

  render() {
    const {
      className,
      children,
      history,
      style = ButtonStyle.Default,
      disabled,
      loading,
      to,
      onClick,
      iconComponent,
      disableTabIndex,
    } = this.props;

    const componentClasses = classNames(styles.Button, {
      [className]: className,
      [styles[style]]: style,
      [styles.Disabled]: disabled,
    });

    const linkClasses = classNames(styles.Link, {
      [styles.leftMargin]: iconComponent,
    });

    return (
      <div
        className={componentClasses}
        tabIndex={disableTabIndex ? -1 : 0}
        onClick={(e) =>
          this.eventHandler(e, disabled, loading, onClick, history, to)
        }
        onKeyDown={(e) =>
          this.eventHandler(e, disabled, loading, onClick, history, to)
        }
      >
        {loading ? (
          <FaSpinner className={styles.Icon} />
        ) : (
          <>
            {iconComponent}
            <span className={linkClasses}>{children}</span>
          </>
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(Button);
