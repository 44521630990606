export default [
  {
    titleI18nKey: 'help_title_text',
    questions: [
      {
        titleI18nKey: 'help_WhatIs',
        answerI18nKey: 'help_WhatIsAnswer',
      },
      {
        titleI18nKey: 'help_HowDoesWork',
        answerI18nKey: 'help_HowDoesWorkAnswer',
      },
      {
        titleI18nKey: 'help_HowCanISendRecommendation',
        answerI18nKey: 'help_HowCanISendRecommendationAnswer',
      },
      {
        titleI18nKey: 'help_HowDoITrackRecommendations',
        answerI18nKey: 'help_HowDoITrackRecommendationsAnswer',
      },
      {
        titleI18nKey: 'help_WhatDoINeedToSignUp',
        answerI18nKey: 'help_WhatDoINeedToSignUpAnswer',
      },
      {
        titleI18nKey: 'help_HowCanIAccess',
        answerI18nKey: 'help_HowCanIAccessAnswer',
      },
      {
        titleI18nKey: 'help_WhoDoICallIfQuestions',
        answerI18nKey: 'help_WhoDoICallIfQuestionsAnswer',
      },
    ],
  },
  {
    titleI18nKey: 'Account',
    questions: [
      {
        titleI18nKey: 'help_HowCanICreateAccount',
        answerI18nKey: 'help_HowCanICreateAccountAnswer',
      },
      {
        titleI18nKey: 'help_HowDoIResetPassword',
        answerI18nKey: 'help_HowDoIResetPasswordAnswer',
      },
    ],
  },
  {
    titleI18nKey: 'help_YourPatientsQuestions',
    questions: [
      {
        titleI18nKey: 'help_CanPatientChangeOrder',
        answerI18nKey: 'help_CanPatientChangeOrderAnswer',
      },
      {
        titleI18nKey: 'help_HowDoPatientsTrackStatus',
        answerI18nKey: 'help_HowDoPatientsTrackStatusAnswer',
      },
      {
        titleI18nKey: 'help_WhatPatientDoIfProblem',
        answerI18nKey: 'help_WhatPatientDoIfProblemAnswer',
      },
      {
        titleI18nKey: 'help_WhatAreDirectShippingOptions',
        answerI18nKey: 'help_WhatAreDirectShippingOptionsAnswer',
      },
      {
        titleI18nKey: 'help_DoesShipInternationally',
        answerI18nKey: 'help_DoesShipInternationallyAnswer',
      },
      {
        titleI18nKey: 'help_HowDoPatientsGetFreeShipping',
        answerI18nKey: 'help_HowDoPatientsGetFreeShippingAnswer',
      },
      {
        titleI18nKey: 'help_WhatIsCDReturnPolicy',
        answerI18nKey: 'help_WhatIsCDReturnPolicyAnswer',
      },
      {
        titleI18nKey: 'help_HowDoesYourPatientReturnOrder',
        answerI18nKey: 'help_HowDoesYourPatientReturnOrderAnswer',
      },
      {
        titleI18nKey: 'help_CanPatientCheckoutWithoutAccount',
        answerI18nKey: 'help_CanPatientCheckoutWithoutAccountAnswer',
      },
    ],
  },
];
