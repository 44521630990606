//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { withAuthUser } from '@helpers/auth';
import { loadEligibileRewardSelections } from '@api/endpoints/get/providers';
import { updateRewardSelection } from '@api/endpoints/post/providers';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import {
  Modal,
  Header,
  Popover,
  Radio,
  Button,
  ButtonStyle,
} from '@cmp/common';
import { FaSpinner } from 'react-icons/fa';
import { findPayoutOption } from '../../../helpers/constants/user';
import { loadClinics } from '../../../api/endpoints/get/providers';
import { storeValue, getValue, removeItem } from '@helpers/localStorage';
import { UserStorageKey } from '@helpers/constants/localStorage';
import { startConnect } from '../../../api/endpoints/post/payments';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

//------------------------------------------------------------------------------

class RewardSelection extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;

    this.state = {
      selectionMade: false,
      selectedOption: -1,
      popoverShow: false,
      selectedDisclaimerText: '',
      loadingState: true,
      disabledOptions: [],
      loadingSaveChanges: false,
      saveStatusNotificationVisible: false,
      saveStatusNotificationText: '',
      latestProviderPayoutOption: '',
      nextProviderPayoutOption: '',
      eligibleRewardSelections: [],
    };

    this.paymentOptions = {
      0: {
        type: 'earnFull',
        description: t('reward_ReceiveViaStripeDeposit'),
      },
      1: {
        type: 'earnSplit',
        description: t('reward_EarnAndColgateContributes'),
      },
      2: {
        type: 'donate',
        description: t('reward_IPreferNotToReceive'),
      },
      3: {
        type: 'earnAmz',
        description: t('reward_ReceiveViaAmazonGiftCards'),
      },
    };

    this.paymentOptionsArray = Array.from(Object.values(this.paymentOptions));

    this.paymentOptionsDisclaimer = {
      0: t('reward_PaymentOptionsDisclaimer1'),
      1: t('reward_PaymentOptionsDisclaimer2'),
      2: t('reward_PaymentOptionsDisclaimer3'),
      3: t('reward_PaymentOptionsDisclaimer4'),
    };

    // Hide 'earnSplit'
    this.hiddenOptions = [1];

    const {
      history,
      location: { pathname, state },
    } = this.props;

    this.onRadioSelection = this.onRadioSelection.bind(this);
    this.onRadioDetailsSelection = this.onRadioDetailsSelection.bind(this);
  }

  navigateBack() {
    history.back();
  }

  onRadioSelection(index) {
    this.setState({ selectionMade: true, selectedOption: index });
  }

  onRadioDetailsSelection(index) {
    if (this.paymentOptionsDisclaimer[index]) {
      this.setState({
        popoverShow: true,
        selectedDisclaimerText: this.paymentOptionsDisclaimer[index],
      });
    }
  }

  async setStripeConnectedIfRequired(confirmedSelection) {
    return new Promise(async (resolve, reject) => {
      if (findPayoutOption(confirmedSelection).requireStripeAccount) {
        const { data: providerData } = await loadClinics();
        // check user has a stripe account
        if (!providerData?.stripeConnected) {
          startConnect()
            .then(({ data }) => {
              const { csrfToken } = data || {};
              if (!csrfToken) return;

              const url = StripeConnectURL(
                window._env.REACT_APP_STRIPE_CLIENT_ID,
                csrfToken,
                getValue(UserStorageKey, true)
              );

              storeValue(StripeRegistrationUrl, url);

              resolve();
            })
            .catch((err) => {
              reject();
            });
        }
      } else {
        removeItem(StripeRegistrationUrl);
        resolve();
      }
    });
  }

  async componentDidMount() {
    const { __authUser, kind } = this.props;
    if (!__authUser) window.location = '/';

    // Fetch the provider's eligible selections
    const {
      eligibleRewardSelections,
      latestProviderPayoutOption,
      nextProviderPayoutOption,
    } = (await loadEligibileRewardSelections()).data;

    const earnSplitIndex = this.paymentOptionsArray.findIndex(
      (rewardOption) => rewardOption.type === 'earnSplit'
    );

    // Only show the earnSplit option if the provider currently has it active
    if (latestProviderPayoutOption !== 'earnSplit') {
      delete this.paymentOptions[earnSplitIndex];

      let intermediateOptions = {},
        intermediateOptionsDisclaimer = {};
      let newIndex = 0;

      for (let [k, v] of Object.entries(this.paymentOptions)) {
        k = parseInt(k);

        if (k === earnSplitIndex) {
          continue;
        }

        intermediateOptions[newIndex] = v;
        newIndex += 1;
      }

      this.paymentOptions = intermediateOptions;

      newIndex = 0;

      for (let [k, v] of Object.entries(this.paymentOptionsDisclaimer)) {
        k = parseInt(k);

        if (k === earnSplitIndex) {
          continue;
        }

        intermediateOptionsDisclaimer[newIndex] = v;
        newIndex += 1;
      }

      this.paymentOptionsDisclaimer = intermediateOptionsDisclaimer;
      this.paymentOptionsArray = Array.from(Object.values(this.paymentOptions));
    }

    const disabledOptions = this.paymentOptionsArray.flatMap(
      ({ type }, index) =>
        eligibleRewardSelections.includes(type) ? [] : index
    );

    this.setState({
      eligibleRewardSelections,
      disabledOptions: disabledOptions,
      nextProviderPayoutOption: this.paymentOptionsArray.findIndex(
        (option) => option.type === nextProviderPayoutOption
      ),
      latestProviderPayoutOption: this.paymentOptionsArray.findIndex(
        (option) => option.type === latestProviderPayoutOption
      ),
      loadingState: false,
    });
  }

  render() {
    const { t } = this.props;
    const componentClasses = classNames(styles.RewardSelection);

    return (
      <div className={componentClasses}>
        <Helmet>
          <title className={styles.Title}>
            {t('reward_UpdateRewardSelection')}
          </title>
        </Helmet>

        <Header />

        <Popover.Generic
          title={t('reward_RewardOptionInformation')}
          subtitle={<span>{this.state.selectedDisclaimerText}</span>}
          onClose={() => {
            this.setState({ popoverShow: false });
          }}
          visible={this.state.popoverShow}
          className={styles.Popover}
        />

        <div className={styles.Wrapper}>
          {this.state.saveStatusNotificationVisible ? (
            <Modal.Generic
              title={this.state.saveStatusNotificationTitle}
              subtitle={this.state.saveStatusNotificationText}
              onClose={() => history.back()}
            ></Modal.Generic>
          ) : (
            <React.Fragment>
              <div className={styles.Header}>
                <h2 className={styles.Title}>
                  {t('reward_UpdateRewardSelection')}
                </h2>
              </div>
              <div className={styles.Content}>
                {this.state.loadingState ? (
                  <FaSpinner className={styles.Spinner} />
                ) : (
                  <React.Fragment>
                    <p className={styles.Subtitle}>
                      {t('reward_RewardSelectionChangesDecember')}
                      <Link className={styles.Link} to="/help">
                        {t('reward_LearnMore')}
                      </Link>
                    </p>
                    {this.state.eligibleRewardSelections.length ? (
                      <div>
                        <div className={styles.Radio}>
                          <Radio
                            selected={false}
                            title={t('reward_YourCurrentSelection')}
                            selectionCallback={() => null}
                            options={[
                              this.paymentOptions[
                                this.state.latestProviderPayoutOption
                              ].description,
                            ]}
                            detailsOptions={{
                              '0': this.paymentOptionsDisclaimer[1],
                            }}
                            detailsCallback={() =>
                              this.onRadioDetailsSelection(
                                this.state.latestProviderPayoutOption
                              )
                            }
                            disabledOptions={this.state.disabledOptions}
                            selectedOption={0}
                          />
                        </div>

                        <div className={styles.Radio}>
                          <Radio
                            selected={false}
                            title={t(
                              'reward_UpdateYourRewardSelectionForYear',
                              { year: new Date().getFullYear() + 1 }
                            )}
                            selectionCallback={this.onRadioSelection}
                            options={Object.values(this.paymentOptions).map(
                              (paymentOption) => paymentOption.description
                            )}
                            detailsOptions={this.paymentOptionsDisclaimer}
                            detailsCallback={this.onRadioDetailsSelection}
                            disabledOptions={this.state.disabledOptions}
                            selectedOption={this.state.nextProviderPayoutOption}
                          />
                        </div>
                      </div>
                    ) : (
                      <Radio
                        selected={false}
                        selectionCallback={this.onRadioSelection}
                        options={Object.values(this.paymentOptions).map(
                          (paymentOption) => paymentOption.description
                        )}
                        detailsOptions={this.paymentOptionsDisclaimer}
                        detailsCallback={this.onRadioDetailsSelection}
                        disabledOptions={this.state.disabledOptions}
                        selectedOption={this.state.latestProviderPayoutOption}
                      />
                    )}
                    <Button
                      style={ButtonStyle.Secondary}
                      className={styles.Button}
                      onClick={async () => {
                        if (
                          !this.state.selectionMade ||
                          this.state.selectedOption === -1
                        ) {
                          return;
                        }

                        this.setState({ loadingSaveChanges: true });

                        const confirmedSelection = this.paymentOptionsArray[
                          this.state.selectedOption
                        ].type;

                        let response = await updateRewardSelection(
                          confirmedSelection
                        );

                        if (response.status !== 200) {
                          this.setState({
                            saveStatusNotificationVisible: true,
                            saveStatusNotificationTitle: t(
                              'reward_RewardSelectionFailed'
                            ),
                            saveStatusNotificationText: t(
                              'reward_ErrorSavingRewardSelection'
                            ),
                            loadingSaveChanges: false,
                          });
                        } else {
                          await this.setStripeConnectedIfRequired(
                            confirmedSelection
                          );

                          this.setState({
                            saveStatusNotificationVisible: true,
                            saveStatusNotificationTitle: t(
                              'reward_RewardSelectionUpdated'
                            ),
                            saveStatusNotificationText: t(
                              'reward_RewardSelectionSaved'
                            ),
                            loadingSaveChanges: false,
                          });
                        }
                      }}
                      disabled={
                        !this.state.selectionMade ||
                        this.state.selectedOption ===
                          this.state.nextProviderPayoutOption ||
                        !this.state.eligibleRewardSelections.length
                      }
                      loading={this.state.loadingSaveChanges}
                    >
                      {t('reward_Save')}
                    </Button>
                    <Button
                      style={ButtonStyle.Secondary}
                      className={styles.Button}
                      onClick={() => {
                        this.navigateBack();
                      }}
                    >
                      {t('reward_Cancel')}
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withAuthUser(withTranslation()(RewardSelection));
