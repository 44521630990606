// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__ViewRecommendationsButton___2R8iC {\n  padding: 9px 23px;\n  font-weight: bold;\n  font-size: 14px;\n  color: white;\n  background-color: #A30B35;\n  text-align: center;\n}\n.index__ViewRecommendationsButton___2R8iC:hover {\n  color: white;\n}", ""]);
// Exports
exports.locals = {
	"ViewRecommendationsButton": "index__ViewRecommendationsButton___2R8iC"
};
module.exports = exports;
