export const PayoutOption = {
  EARN_FULL: {
    key: 'earnFull',
    requireStripeAccount: false,
  },
  EARN_AMZ: {
    key: 'earnAmz',
    requireStripeAccount: false,
  },
  EARN_SPLIT: {
    key: 'earnSplit',
    requireStripeAccount: false,
  },
  DONATE: {
    key: 'donate',
    requireStripeAccount: false,
  },
};

const allOptions = [
  PayoutOption.EARN_FULL,
  PayoutOption.EARN_SPLIT,
  PayoutOption.DONATE,
  PayoutOption.EARN_AMZ,
];

export const findPayoutOption = (key) => {
  return allOptions.find((x) => x.key === key);
};
