// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Media___1G4rE {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  padding: 50px 20px 20px 20px;\n  min-width: 0;\n  width: auto;\n  height: 100%;\n}\n.index__Media___1G4rE.index__MediaVideo___3j_mb {\n  min-height: 0;\n  max-height: none;\n  height: auto;\n  height: 80%;\n  width: 100%;\n}\n.index__Media___1G4rE.index__MediaVideo___3j_mb .index__Content___3ypdr > * {\n  width: 100%;\n  height: 100%;\n}\n.index__Media___1G4rE .index__Content___3ypdr {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 2;\n}\n.index__Media___1G4rE .index__Content___3ypdr .index__Image___180So {\n  flex-grow: 2;\n  flex-basis: 0;\n  min-width: 0;\n  min-height: 0;\n  object-fit: contain;\n}\n@media only screen and (max-width: 955px) {\n  .index__Media___1G4rE.index__MediaVideo___3j_mb {\n    height: 60%;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Media": "index__Media___1G4rE",
	"MediaVideo": "index__MediaVideo___3j_mb",
	"Content": "index__Content___3ypdr",
	"Image": "index__Image___180So"
};
module.exports = exports;
