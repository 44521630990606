// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__Footer___1eN6g {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 46px;\n  font-size: 14px;\n  background-color: #fafafb;\n  color: #54565a;\n  font-weight: normal;\n}\n.index__Footer___1eN6g .index__Disclosure___1vF3V,\n.index__Footer___1eN6g .index__Link___--XsM {\n  font-size: inherit;\n  color: inherit;\n  font-weight: normal;\n  text-transform: none;\n}\n.index__Footer___1eN6g .index__Docs___2GMgl {\n  display: flex;\n}\n.index__Footer___1eN6g .index__Docs___2GMgl > * {\n  margin-right: 12px;\n}\n.index__Footer___1eN6g .index__Docs___2GMgl > *:last-child {\n  margin-right: 0;\n}\n@media only screen and (max-width: 955px) {\n  .index__Footer___1eN6g {\n    flex-direction: column;\n    text-align: center;\n    padding: 20px 12px 80px 12px;\n  }\n  .index__Footer___1eN6g > * {\n    margin-bottom: 18px;\n  }\n  .index__Footer___1eN6g > *:last-child {\n    margin-bottom: 0;\n  }\n  .index__Footer___1eN6g .index__Docs___2GMgl {\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n  }\n  .index__Footer___1eN6g .index__Docs___2GMgl > * {\n    margin-right: 0;\n  }\n  .index__Footer___1eN6g .index__Docs___2GMgl > *:last-child {\n    margin-right: 0;\n  }\n  .index__Footer___1eN6g .index__Docs___2GMgl > * {\n    margin-bottom: 12px;\n  }\n  .index__Footer___1eN6g .index__Docs___2GMgl > *:last-child {\n    margin-bottom: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"Footer": "index__Footer___1eN6g",
	"Disclosure": "index__Disclosure___1vF3V",
	"Link": "index__Link___--XsM",
	"Docs": "index__Docs___2GMgl"
};
module.exports = exports;
